import React from "react";
import { Link } from "react-router-dom";
import { ConfigsIcon } from "../../assets/images/icons/ConfigsIcon/ConfigsIcon";
import { RoleConfigIcon } from "../../assets/images/icons/RoleConfigIcon/RoleConfigIcon";
import { UsersIcon } from "../../assets/images/icons/UsersIcon/UsersIcon";
import { LlmScanIcon } from "../../assets/images/icons/LlmScanIcon/LlmScanIcon";
import { DashboardIcon } from "../../assets/images/icons/DashboardIcon/DashboardIcon";

function ButtonComponent({
  classNames,
  text,
  link_to,
  disabled = false,
  external,
}) {
  const baseClasses = `h-12 w-48 mx-auto font-bold flex ${classNames}`;
  const disabledClasses = disabled ? "cursor-not-allowed opacity-50" : "";

  const renderIcon = () => {
    switch (link_to) {
      case "/dashboard":
        return <DashboardIcon />;
      case "/configs":
        return <ConfigsIcon />;
      case "/roles":
        return <RoleConfigIcon />;
      case "/users":
        return <UsersIcon />;
      case "/ai_scanner":
        return <LlmScanIcon />;
      default:
        return null;
    }
  };

  const content = (
    <div className="inline-flex items-center ml-6">
      <div className="mr-2">{renderIcon()}</div>
      <p>{text}</p>
    </div>
  );

  if (external) {
    if (disabled) {
      // Render as a div when disabled to prevent clicks
      return (
        <div className={`${baseClasses} ${disabledClasses}`}>{content}</div>
      );
    } else {
      return (
        <a
          className={`${baseClasses}`}
          href={link_to}
          target="_blank"
          rel="noopener noreferrer"
        >
          {content}
        </a>
      );
    }
  } else {
    if (disabled) {
      // Render as a div when disabled to prevent clicks
      return (
        <div className={`${baseClasses} ${disabledClasses}`}>{content}</div>
      );
    } else {
      return (
        <Link className={`${baseClasses}`} to={link_to}>
          {content}
        </Link>
      );
    }
  }
}

export default ButtonComponent;
