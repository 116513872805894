import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import client from "../../../client";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { BarChart } from "@mui/x-charts/BarChart";
import { MODELS_TRUST_METRICS } from "../../../constants/supportedModels";
import theme from "../../../theme";
import MultiSelectComponent from "../../common/MultiSelectComponent";
import { InfoOutlined } from "@mui/icons-material";
import { DEFAULT_CATEGORIES_SETTING } from "../../../constants/supportedModels";


const ComparisonDashboardModal = ({ tenantId }) => {
  // Alias category scores data from the new endpoint
  const [aliasCategoryScoresData, setAliasCategoryScoresData] = useState([]);
  const [aliasScoresData, setAliasScoresData] = useState([]);

  // Fetching the aliasScore or aliasCategory data
  const [isLoading, setLoading] = useState(true);

  // for filter value
  const [selectedModels, setSelectedModels] = useState(["All"]);
  const [selectedMetrics, setSelectedMetrics] = useState(["All"]);

  // for metrics chart
  const [metricsChart, setMetricsChart] = useState(
    Object.keys(MODELS_TRUST_METRICS)
  );

  const [categories, setCategories] = useState(DEFAULT_CATEGORIES_SETTING);

  useEffect(() => {
    setMetricsChart(
      selectedMetrics.includes("All")
        ? Object.keys(MODELS_TRUST_METRICS)
        : selectedMetrics
    );
  }, [selectedMetrics]);

  useEffect(() => {
    // Declare an async function inside the useEffect to handle fetching data
    const fetchData = async () => {
      try {
        // Set loading to true before starting the fetches
        setLoading(true);

        // Execute all fetch functions in parallel
        await Promise.all([
          fetchTenantCategory(),
          fetchAliasScoresData(),
          fetchAliasCategoryScoresData(),
          fetchTenantAliasScoresData(),
          fetchTenantAliasCategoryData(),
        ]);

        // Once all data is fetched, set loading to false
        setLoading(false);
      } catch (error) {
        // Handle error if one of the fetches fails
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading to false even in case of error
      }
    };

    // Call the fetchData function
    fetchData();
  }, []);

  // Fetch alias scores data
  const fetchAliasScoresData = async () => {
    try {
      const response_model = await client.get("/api/alias_scores");
      setAliasScoresData((prevData) => {
        // Check if the "Models" group already exists in the state
        const groupExists = prevData.find((item) => item.group === "Models");

        if (groupExists) {
          // If "Models" exists, update only the options for that group
          return prevData.map(
            (item) =>
              item.group === "Models"
                ? { ...item, options: response_model.data } // Update the options for "Models"
                : item // Keep other groups as they are
          );
        } else {
          // If "Models" does not exist, add a new entry for it
          return [
            { group: "Models", options: response_model.data }, // Add the "Models" group with new options
            ...prevData,
          ];
        }
      });
    } catch (error) {
      console.error(
        "An error occurred while retrieving alias scores data:",
        error
      );
    }
  };

  // Fetch the alias category scores data
  const fetchAliasCategoryScoresData = async () => {
    try {
      const response_model = await client.get("/api/alias_category_scores");
      setAliasCategoryScoresData((prevData) => {
        // Check if the "Models" group already exists in the state
        const groupExists = prevData.find((item) => item.group === "Models");

        if (groupExists) {
          // If "Models" exists, update only the options for that group
          return prevData.map(
            (item) =>
              item.group === "Models"
                ? { ...item, options: response_model.data } // Update the options for "Models"
                : item // Keep other groups as they are
          );
        } else {
          // If "Models" does not exist, add a new entry for it
          return [
            { group: "Models", options: response_model.data }, // Add the "Models" group with new options
            ...prevData,
          ];
        }
      });
    } catch (error) {
      console.error(
        "An error occurred while retrieving alias category scores data:",
        error
      );
    }
  };

  // Fetch tenant alias scores data (if needed for other charts)
  const fetchTenantAliasScoresData = async () => {
    try {
      const response_tenant_policy = await client.get(
        `/api/tenant_alias_scores/${tenantId}`
      );

      const policy_data = response_tenant_policy?.data.filter((item) =>
        item.alias.startsWith("AIPolicy-")
      );
      const endpoint_data = response_tenant_policy?.data.filter(
        (item) => !item.alias.startsWith("AIPolicy-")
      );

      setAliasScoresData((prevData) => {
        // Check if "Policies" and "Private Endpoints" already exist in the state
        const updatedData = prevData.map((item) => {
          if (item.group === "Policies") {
            return { ...item, options: policy_data }; // Update Policy options
          } else if (item.group === "Private Endpoints") {
            return { ...item, options: endpoint_data }; // Update Endpoints options
          }
          return item; // Keep other groups as they are
        });

        // If either "Policies" or "Private Endpoints" don't exist, add them
        const groups = updatedData.map((item) => item.group);
        if (!groups.includes("Policies")) {
          updatedData.push({ group: "Policies", options: policy_data });
        }
        if (!groups.includes("Private Endpoints")) {
          updatedData.push({
            group: "Private Endpoints",
            options: endpoint_data,
          });
        }

        return updatedData;
      });
    } catch (error) {
      console.error("Error fetching tenant alias scores data:", error);
    }
  };

  // Fetch the new alias category scores data
  const fetchTenantAliasCategoryData = async () => {
    try {
      const response_tenant_policy = await client.get(
        `/api/tenant_alias_category_scores/${tenantId}`
      );

      const policy_data = response_tenant_policy?.data.filter((item) =>
        item.alias.startsWith("AIPolicy-")
      );
      const endpoint_data = response_tenant_policy?.data.filter(
        (item) => !item.alias.startsWith("AIPolicy-")
      );
      setAliasCategoryScoresData((prevData) => {
        // Check if "Policies" and "Private Endpoints" already exist in the state
        const updatedData = prevData.map((item) => {
          if (item.group === "Policies") {
            return { ...item, options: policy_data }; // Update Policy options
          } else if (item.group === "Private Endpoints") {
            return { ...item, options: endpoint_data }; // Update Endpoints options
          }
          return item; // Keep other groups as they are
        });

        // If either "Policies" or "Private Endpoints" don't exist, add them
        const groups = updatedData.map((item) => item.group);
        if (!groups.includes("Policies")) {
          updatedData.push({ group: "Policies", options: policy_data });
        }
        if (!groups.includes("Private Endpoints")) {
          updatedData.push({
            group: "Private Endpoints",
            options: endpoint_data,
          });
        }

        return updatedData;
      });
    } catch (error) {
      console.error("Error fetching tenant alias category scores data:", error);
    }
  };

      // Fetch tenant alias scores data (if needed for other charts)
      const fetchTenantCategory = async () => {
        try {
          const response_category_settings = await client.get(
            `/category_settings`
          );
  
          setCategories(response_category_settings.data?.categories || DEFAULT_CATEGORIES_SETTING);
        } catch (error) {
          console.error("Error fetching tenant alias scores data:", error);
        }
      };

  // Custom valueFormatter function to break the word after the first "-"
  const labelFormatter = (value) => {
    // Split the string at the first "-" and wrap the text
    const parts = value.split(/-(.+)/);
    if (parts.length > 1) {
      return `${parts[0]}\n${parts[1]}`;
    }
    return value;
  };

  // Function to render the Bar Chart using aliasScoreData
  const renderModelTrustScoresChart = () => {
    if (selectedModels.length === 0) {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            Please select a model to view the data.
          </Typography>
        </Box>
      );
    }

    // Find the selected model data from aliasScoresData
    let modelData = [];
    aliasScoresData.forEach((item) => {
      item?.options.forEach((option) => {
        if (
          selectedModels.includes(option.alias) ||
          selectedModels.includes("All")
        ) {
          modelData.push({
            alias: option.alias,
            score: option.score.score,
            information_codes: option.score.information_codes,
          });
        }
      });
    });

    if (modelData.length === 0) {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            No data available for the chosen models.
          </Typography>
        </Box>
      );
    }

    modelData.sort((a, b) => b.score - a.score);

    // Prepare the bar chart data
    const barChartData = modelData.map((item) => {
      return {
        x: item.alias,
        y: item.score,
        information_codes: item.information_codes,
      };
    });

    // Apply colors
    const barColors = barChartData.map((item) => {
      const categoryObj = categories.find(
        (cat) => item.y >= cat.min_score && item.y <= cat.max_score
      );
      return categoryObj ? categoryObj.color : "#cccccc";
    });

    return (
      <Box sx={{ width: "100%", height: "100%", flexGrow: 1 }}>
        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: barChartData.map((item) => item.x),
              label: barChartData.length < 9 ? "Models" : "",
              labelStyle: {
                fontSize: 16,
              },
              valueFormatter: labelFormatter,
              tickLine: true,
              tickLabelStyle: {
                textAnchor: barChartData.length > 8 ? "end" : "middle",
                fontSize: barChartData.length > 5 ? 9 : 12,
                angle: barChartData.length > 8 ? -25 : 0,
              },
            },
          ]}
          yAxis={[
            {
              scaleType: "linear",
              min: 0,
              max: 1000,
              label: "AI Trust Score",
              labelStyle: {
                fontSize: 16,
              },
              ticks: [0, 250, 500, 750, 1000],
            },
          ]}
          series={[
            {
              data: barChartData.map((item) => item.y),
              id: "model-score-bar-chart",
              valueFormatter: (v, { dataIndex }) => {
                const { information_codes } = barChartData[dataIndex] || {};
                return (
                  <>
                    <strong>AI Trust Score: </strong>
                    {v}
                    <br />
                    {information_codes &&
                      Object.keys(information_codes).length !== 0 && (
                        <>
                          <strong>Information Code:</strong>
                          <br />
                          {Object.entries(information_codes).map(
                            ([key, value]) => (
                              <i key={key}>
                                {key} : {value}
                                <br />
                              </i>
                            )
                          )}
                        </>
                      )}
                  </>
                );
              },
            },
          ]}
          tooltip={{
            show: true,
          }}
          margin={{ bottom: barChartData.length < 9 ? 60 : 80 }}
          trigger="item"
          barLabel="value"
          sx={{
            width: "100%",
            height: "100%",
            marginBottom: "50px",
            [`& .MuiBarElement-root`]: barChartData.reduce(
              (style, _, index) => {
                style[`&:nth-of-type(${index + 1})`] = {
                  fill: barColors[index],
                };
                return style;
              },
              {}
            ),
            [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
              transform: "translateX(-20px)",
            },
            [`& .${axisClasses.directionX} .${axisClasses.label}`]: {
              transform: "translateY(15px)",
            },
          }}
        />
      </Box>
    );
  };

  // Function to render the Bar Chart using aliasCategoryScoresData
  const renderBarChart = (metric) => {
    if (selectedModels.length === 0) {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            Please select a model to view the data.
          </Typography>
        </Box>
      );
    }

    // Find the selected model data from aliasCategoryScoresData
    let modelData = [];
    aliasCategoryScoresData.forEach((item) => {
      item?.options.forEach((option) => {
        if (
          selectedModels.includes(option.alias) ||
          selectedModels.includes("All")
        ) {
          modelData.push({
            alias: option.alias,
            score: option.categories[metric],
          });
        }
      });
    });

    if (modelData.length === 0) {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            No data available for the chosen models.
          </Typography>
        </Box>
      );
    }

    modelData.sort((a, b) => b.score - a.score);

    // Prepare the bar chart data
    const barChartData = modelData.map((item) => {
      return { x: item.alias, y: item.score };
    });

    // Apply colors
    const barColors = barChartData.map((item) => {
      const categoryObj = categories.find(
        (cat) => item.y >= cat.min_score && item.y <= cat.max_score
      );
      return categoryObj ? categoryObj.color : "#cccccc";
    });

    const isMultipleMetrics = metricsChart.length > 1;

    return (
      <Box sx={{ width: "100%", height: "100%", flexGrow: 1 }}>
        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: barChartData.map((item) => item.x),
              label:
                barChartData.length < (isMultipleMetrics ? 4 : 9)
                  ? "Models"
                  : "",
              labelStyle: {
                fontSize: 16,
              },
              valueFormatter: labelFormatter,
              tickLine: true,
              tickLabelStyle: {
                textAnchor:
                  barChartData.length > (isMultipleMetrics ? 3 : 8)
                    ? "end"
                    : "middle",
                fontSize:
                  barChartData.length > (isMultipleMetrics ? 2 : 5)
                    ? isMultipleMetrics
                      ? 8
                      : 9
                    : 12,
                angle:
                  barChartData.length > (isMultipleMetrics ? 3 : 8)
                    ? isMultipleMetrics
                      ? -50
                      : -25
                    : 0,
              },
            },
          ]}
          yAxis={[
            {
              scaleType: "linear",
              min: 0,
              max: 1000,
              label: "Category Score",
              labelStyle: {
                fontSize: 16,
              },
              ticks: [0, 250, 500, 750, 1000],
            },
          ]}
          series={[
            {
              data: barChartData.map((item) => item.y),
              id: `${metric.toLowerCase().replaceAll(" ", "-")}-bar-chart`,
            },
          ]}
          tooltip={{ show: true }}
          margin={{
            bottom: barChartData.length < (isMultipleMetrics ? 4 : 9) ? 60 : 80,
          }}
          barLabel={barChartData.length > 13 ? "" : "value"}
          sx={{
            width: "100%",
            height: "100%",
            marginBottom: "50px",
            [`& .MuiBarElement-root`]: barChartData.reduce(
              (style, _, index) => {
                style[`&:nth-of-type(${index + 1})`] = {
                  fill: barColors[index],
                };
                return style;
              },
              {}
            ),
            [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
              transform: "translateX(-20px)",
            },
            [`& .${axisClasses.directionX} .${axisClasses.label}`]: {
              transform: "translateY(15px)",
            },
          }}
        />
      </Box>
    );
  };

  const generateModelMenuItems = () => {
    const groupOptions = {};

    // Helper function to process each dataset
    const processData = (data) => {
      data.forEach((item) => {
        const { group, options = [] } = item;
        if (!groupOptions[group]) {
          groupOptions[group] = new Set();
        }
        options.forEach((option) => groupOptions[group].add(option.alias));
      });
    };

    // Process both datasets
    processData(aliasCategoryScoresData);
    processData(aliasScoresData);

    // Convert Set to array and return the result
    return Object.entries(groupOptions).map(([group, options]) => ({
      group,
      options: [...options], // Spread operator to convert Set to Array
    }));
  };

  return (
    <Box>
      {/* Models and Metrics MultiSelect Filters */}
      <>
        <Paper
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Grid container spacing={3} justifyContent="left">
            <Grid item xs={7}>
              <MultiSelectComponent
                label="AI Endpoints"
                options={generateModelMenuItems()}
                selectedItems={selectedModels}
                setSelectedItems={setSelectedModels}
              />
            </Grid>
            <Grid item xs={5}>
              <MultiSelectComponent
                label="Metrics"
                options={Object.keys(MODELS_TRUST_METRICS)}
                selectedItems={selectedMetrics}
                setSelectedItems={setSelectedMetrics}
              />
            </Grid>
          </Grid>
        </Paper>
      </>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ marginTop: 0.5 }}
          >
            {/* Model Trust Score */}
            <Grid item xs={12}>
              <Paper
                sx={{
                  height: "450px",
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "300px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: theme.tmryk_background_color,
                    padding: 1,
                    borderRadius: "8px 8px 0px 0px",
                  }}
                >
                  <Typography
                    variant="string"
                    align="center"
                    gutterBottom
                    style={{ color: "white", height: "15px" }}
                  >
                    AI Trust Score<sup style={{ fontSize: "0.6em" }}>TM</sup>
                  </Typography>
                </Box>
                <Box sx={{ flexGrow: 1, height: "100%" }}>
                  {renderModelTrustScoresChart()}
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            justifyContent="left"
            sx={{ marginTop: 0.5 }}
          >
            {/* Metrics Chart */}
            {metricsChart.map((metric) => {
              return (
                <Grid item xs={metricsChart.length > 1 ? 6 : 12} key={metric}>
                  <Paper
                    sx={{
                      height: "350px",
                      display: "flex",
                      flexDirection: "column",
                      minHeight: "300px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: theme.tmryk_background_color,
                        padding: 1,
                        borderRadius: "8px 8px 0px 0px",
                      }}
                    >
                      <Typography
                        variant="string"
                        align="center"
                        gutterBottom
                        style={{
                          color: "white",
                          marginLeft: "16px",
                          height: "15px",
                        }}
                      >
                        {metric}
                      </Typography>
                      <Tooltip title={MODELS_TRUST_METRICS[metric]} arrow>
                        <InfoOutlined
                          fontSize="small"
                          sx={{ color: "white", marginRight: "8px" }}
                        />
                      </Tooltip>
                    </Box>
                    <Box sx={{ flexGrow: 1, height: "100%" }}>
                      {renderBarChart(metric)}
                    </Box>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>{" "}
        </>
      )}
    </Box>
  );
};

export default ComparisonDashboardModal;
