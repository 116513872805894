import React, { useEffect, useState } from "react";
import {
  Button,
  CssBaseline,
  Box,
  Typography,
  Container,
  createTheme,
  ThemeProvider,
  Alert,
  CircularProgress,
} from "@mui/material";
import tmrykLogo from "../assets/images/tmryk-logo-login.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Verified } from "@mui/icons-material";
import client from "../client";
import { toast } from "react-toastify";

const defaultTheme = createTheme();

export default function VerifyAccount() {
  const [token, setToken] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Function to parse query parameters from the URL
  const queryParams = new URLSearchParams(location.search);

  // Set default values from query params on page load
  useEffect(() => {
    setToken(queryParams.get("token") || "");
  }, [location.search]); // Re-run whenever the query string changes

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");
    setSuccess(false);

    // Validate Confirm Password
    if (!token) {
      setIsSubmitting(false);
      setErrorMessage("Token required to verify the account!");
      return;
    }

    client
      .post("/verify_account", { token: token })
      .then(() => {
        setIsSubmitting(false);
        setSuccess(true);
        toast.success("Account Verified Successfully!");
        // Wait for 5 sec to display the account verification message.
        setTimeout(() => {
          navigate("/login", { replace: true });
        }, 5000);
      })
      .catch((error) => {
        setIsSubmitting(false);
        if (error.response.status === 401) {
          setErrorMessage("Your token is invalid or expired.");
        } else if (typeof error.response?.data?.detail === "string") {
          setErrorMessage(error.response?.data?.detail);
        } else {
          setErrorMessage("Something went wrong! Please try again later.");
        }
      });
  };

  return (
    <div>
      <div>
        <img
          src={tmrykLogo}
          alt="tmryk logo"
          className="w-[32rem] h-48 mx-auto mt-10"
        />
      </div>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Verify Your Tumeryk Account
            </Typography>
            <Typography
              align="center"
              gutterBottom={true}
              color="gray"
              component="h2"
              variant="subtitle1"
              sx={{ mt: 2 }}
            >
              Please click on "Verify Account" to get  access to your Tumeryk account.
            </Typography>
            {errorMessage && (
              <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
                {errorMessage}
              </Alert>
            )}
            {isSuccess && (
              <Alert icon={<Verified fontSize="inherit" />} severity="success">
                Your account has been verified successfully.
              </Alert>
            )}
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 3 }}
            >
              {!isSubmitting ? (
                <Button
                  disabled={isSubmitting || isSuccess}
                  type="submit"
                  size="large"
                  variant="outlined"
                  fullWidth
                  sx={{ mt: 1, mb: 2, height: "70px" }}
                  endIcon={!isSubmitting ? <Verified /> : ""}
                >
                  Verify Account
                </Button>
              ) : (
                <CircularProgress />
              )}
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
}
