import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  ListSubheader,
} from "@mui/material";
import { PieChart, useDrawingArea } from "@mui/x-charts";
import client from "../../../client";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { BarChart } from "@mui/x-charts/BarChart";
import { styled } from "@mui/material/styles";
import theme from "../../../theme";
import {
  DEFAULT_CATEGORIES_SETTING,
  DEFAULT_TRUST_METRICS_WEIGHTS,
} from "../../../constants/supportedModels";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left", // Align the dropdown to the left
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left", // Make sure the dropdown opens left-aligned
  },
};

const ModelChartPanel = ({ tenantId }) => {
  // Alias category scores data
  const [aliasCategoryScoresData, setAliasCategoryScoresData] = useState([]);
  const [loadingAliasCategoryScoresData, setLoadingAliasCategoryScoresData] =
    useState(true);

  // Alias Score Data
  const [aliasScoresData, setAliasScoresData] = useState([]);
  const [loadingAliasScoresData, setLoadingAliasScoresData] = useState(true);

  // Single state variable for model selection
  const [selectedModel, setSelectedModel] = useState("");

  const [categories, setCategories] = useState(DEFAULT_CATEGORIES_SETTING);
  const [trustMetricsWeights, setTrustMetricsWeights] = useState(
    DEFAULT_TRUST_METRICS_WEIGHTS
  );

  useEffect(() => {
    // Declare an async function inside the useEffect to handle fetching data
    const fetchData = async () => {
      try {
        // Set loading to true before starting the fetches
        setLoadingAliasScoresData(true);
        setLoadingAliasCategoryScoresData(true);

        // Execute all fetch functions in parallel
        await Promise.all([
          fetchTenantCategory(),
          fetchTrustMetricsWeights(),
          fetchAliasScoresData(),
          fetchAliasCategoryScoresData(),
          fetchTenantAliasScoresData(),
          fetchTenantAliasCategoryData(),
        ]);


        // Once all data is fetched, set loading to false
        setLoadingAliasScoresData(false);
        setLoadingAliasCategoryScoresData(false);
      } catch (error) {
        // Handle error if one of the fetches fails
        console.error("Error fetching data:", error);
        setLoadingAliasScoresData(false);
        setLoadingAliasCategoryScoresData(false);
      }
    };

    // Call the fetchData function
    fetchData();
  }, []);

  // Fetch alias scores data
  const fetchAliasScoresData = async () => {
    try {
      const response_model = await client.get("/api/alias_scores");
      setAliasScoresData((prevData) => {
        // Check if the "Models" group already exists in the state
        const groupExists = prevData.find((item) => item.group === "Models");

        if (groupExists) {
          // If "Models" exists, update only the options for that group
          return prevData.map(
            (item) =>
              item.group === "Models"
                ? { ...item, options: response_model.data } // Update the options for "Models"
                : item // Keep other groups as they are
          );
        } else {
          // If "Models" does not exist, add a new entry for it
          return [
            { group: "Models", options: response_model.data }, // Add the "Models" group with new options
            ...prevData,
          ];
        }
      });
    } catch (error) {
      console.error(
        "An error occurred while retrieving alias scores data:",
        error
      );
    }
  };

  // Fetch the alias category scores data
  const fetchAliasCategoryScoresData = async () => {
    try {
      const response_model = await client.get("/api/alias_category_scores");
      setAliasCategoryScoresData((prevData) => {
        // Check if the "Models" group already exists in the state
        const groupExists = prevData.find((item) => item.group === "Models");

        if (groupExists) {
          // If "Models" exists, update only the options for that group
          return prevData.map(
            (item) =>
              item.group === "Models"
                ? { ...item, options: response_model.data } // Update the options for "Models"
                : item // Keep other groups as they are
          );
        } else {
          // If "Models" does not exist, add a new entry for it
          return [
            { group: "Models", options: response_model.data }, // Add the "Models" group with new options
            ...prevData,
          ];
        }
      });
    } catch (error) {
      console.error(
        "An error occurred while retrieving alias category scores data:",
        error
      );
    }
  };

  // Fetch tenant alias scores data (if needed for other charts)
  const fetchTenantAliasScoresData = async () => {
    try {
      const response_tenant_policy = await client.get(
        `/api/tenant_alias_scores/${tenantId}`
      );

      const policy_data = response_tenant_policy?.data.filter((item) =>
        item.alias.startsWith("AIPolicy-")
      );
      const endpoint_data = response_tenant_policy?.data.filter(
        (item) => !item.alias.startsWith("AIPolicy-")
      );

      setAliasScoresData((prevData) => {
        // Check if "Policies" and "Private Endpoints" already exist in the state
        const updatedData = prevData.map((item) => {
          if (item.group === "Policies") {
            return { ...item, options: policy_data }; // Update Policy options
          } else if (item.group === "Private Endpoints") {
            return { ...item, options: endpoint_data }; // Update Endpoints options
          }
          return item; // Keep other groups as they are
        });

        // If either "Policies" or "Private Endpoints" don't exist, add them
        const groups = updatedData.map((item) => item.group);
        if (!groups.includes("Policies")) {
          updatedData.push({ group: "Policies", options: policy_data });
        }
        if (!groups.includes("Private Endpoints")) {
          updatedData.push({
            group: "Private Endpoints",
            options: endpoint_data,
          });
        }

        return updatedData;
      });
    } catch (error) {
      console.error("Error fetching tenant alias scores data:", error);
    }
  };

  // Fetch the new alias category scores data
  const fetchTenantAliasCategoryData = async () => {
    try {
      const response_tenant_policy = await client.get(
        `/api/tenant_alias_category_scores/${tenantId}`
      );

      const policy_data = response_tenant_policy?.data.filter((item) =>
        item.alias.startsWith("AIPolicy-")
      );
      const endpoint_data = response_tenant_policy?.data.filter(
        (item) => !item.alias.startsWith("AIPolicy-")
      );
      setAliasCategoryScoresData((prevData) => {
        // Check if "Policies" and "Private Endpoints" already exist in the state
        const updatedData = prevData.map((item) => {
          if (item.group === "Policies") {
            return { ...item, options: policy_data }; // Update Policy options
          } else if (item.group === "Private Endpoints") {
            return { ...item, options: endpoint_data }; // Update Endpoints options
          }
          return item; // Keep other groups as they are
        });

        // If either "Policies" or "Private Endpoints" don't exist, add them
        const groups = updatedData.map((item) => item.group);
        if (!groups.includes("Policies")) {
          updatedData.push({ group: "Policies", options: policy_data });
        }
        if (!groups.includes("Private Endpoints")) {
          updatedData.push({
            group: "Private Endpoints",
            options: endpoint_data,
          });
        }

        return updatedData;
      });
    } catch (error) {
      console.error("Error fetching tenant alias category scores data:", error);
    }
  };

  const fetchTenantCategory = async () => {
    try {
      const response_category_settings = await client.get(`/category_settings`);

      setCategories(
        response_category_settings.data?.categories ||
          DEFAULT_CATEGORIES_SETTING
      );
    } catch (error) {
      console.error("Error fetching tenant alias scores data:", error);
    }
  };

  const fetchTrustMetricsWeights = async () => {
    try {
      const response_metrics_settings = await client.get(
        `/trust_metric_settings`
      );

      setTrustMetricsWeights(
        response_metrics_settings.data?.metric_weights ||
          DEFAULT_TRUST_METRICS_WEIGHTS
      );
    } catch (error) {
      console.error("Error fetching tenant alias scores data:", error);
    }
  };

  // Auto-select the first model on page load when aliasCategoryScoresData is loaded
  useEffect(() => {
    if (aliasCategoryScoresData.length > 0 && !selectedModel) {
      const models = aliasCategoryScoresData.flatMap((group) =>
        group.options.map((option) => option.alias)
      );
      if (models.length > 0) {
        setSelectedModel(models[0]);
      }
    }
  }, [aliasCategoryScoresData, selectedModel]);

  const handleModelChange = (event) => {
    const value = event.target.value || "";
    setSelectedModel(value);
  };

  // Function to render the Bar Chart using aliasCategoryScoresData
  const renderBarChart = () => {
    if (!selectedModel || selectedModel === "") {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            Please select a model to view the data.
          </Typography>
        </Box>
      );
    }

    // Find the selected model data from aliasCategoryScoresData
    let modelData = null;
    aliasCategoryScoresData.forEach((item) => {
      const tempModelData = item?.options.filter(
        (option) => option.alias === selectedModel
      );
      if (tempModelData.length > 0) {
        modelData = tempModelData[0];
      }
    });

    if (!modelData) {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            No data available for the chosen model.
          </Typography>
        </Box>
      );
    }

    const categoryEntries = Object.entries(modelData.categories);
    if (categoryEntries.length === 0) {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            No categories found for the chosen model.
          </Typography>
        </Box>
      );
    }

    // Prepare the bar chart data
    const barChartData = categoryEntries.map(([categoryName, score]) => {
      return { x: categoryName, y: score };
    });

    // Apply colors
    const barColors = barChartData.map((item) => {
      const categoryObj = categories.find(
        (cat) => item.y >= cat.min_score && item.y <= cat.max_score
      );
      return categoryObj ? categoryObj.color : "#cccccc";
    });

    return (
      <Box sx={{ width: "100%", height: "100%", flexGrow: 1 }}>
        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: barChartData.map((item) => item.x),
              label: "Categories",
              labelStyle: {
                fontSize: 14,
              },
              tickLine: true,
              tickLabelStyle: {
                textAnchor: "end",
                fontSize: 9,
                angle: -25,
              },
            },
          ]}
          yAxis={[
            {
              scaleType: "linear",
              min: 0,
              max: 1000,
              label: "Category Score",
              ticks: [0, 250, 500, 750, 1000],
            },
          ]}
          series={[
            {
              data: barChartData.map((item) => item.y),
              id: "dynamic-bar-chart",
            },
          ]}
          tooltip={{ show: true }}
          margin={{ bottom: 100 }}
          sx={{
            width: "100%",
            height: "100%",
            marginBottom: "60px",
            [`& .MuiBarElement-root`]: barChartData.reduce(
              (style, _, index) => {
                style[`&:nth-of-type(${index + 1})`] = {
                  fill: barColors[index],
                };
                return style;
              },
              {}
            ),
            [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
              transform: "translateX(-20px)",
            },
            [`& .${axisClasses.directionX} .${axisClasses.label}`]: {
              transform: "translateY(55px)",
            },
          }}
        />
      </Box>
    );
  };

  const generateModelMenuItems = () => {
    const items = [];

    const groupOptions = {};

    // Helper function to process each dataset
    const processData = (data) => {
      data.forEach((item) => {
        const { group, options = [] } = item;
        if (!groupOptions[group]) {
          groupOptions[group] = new Set();
        }
        options.forEach((option) => groupOptions[group].add(option.alias));
      });
    };

    // Process both datasets
    processData(aliasCategoryScoresData);
    processData(aliasScoresData);

    // Convert Set to array and return the result
    const generatedOptions = Object.entries(groupOptions).map(
      ([group, options]) => ({
        group,
        options: [...options], // Spread operator to convert Set to Array
      })
    );

    generatedOptions.map((item) => {
      if (item.options.length > 0) {
        items.push(
          <ListSubheader key={`${item.group}-header`}>
            {item.group}
          </ListSubheader>
        );

        item.options.map((opt) =>
          items.push(
            <MenuItem key={opt} value={opt}>
              {opt.replace("AIPolicy-", "").replace("PrivateEndpoint-", "")}
            </MenuItem>
          )
        );
      }
      return null; // Skip empty groups
    });

    return items;
  };

  const getAliasScoreData = () => {
    if (!selectedModel || !aliasScoresData) {
      return null;
    }

    let aliasData = null;
    aliasScoresData.forEach((item) => {
      const tempAliasData = item?.options.filter(
        (option) => option.alias === selectedModel
      );
      if (tempAliasData.length > 0) {
        aliasData = tempAliasData[0].score.score;
      }
    });
    return aliasData;
  };

  const generateCustomTrustScore = () => {
    if (!selectedModel || !aliasCategoryScoresData) {
      return null;
    }

    let customScore = null;
    aliasCategoryScoresData.forEach((item) => {
      const tempCategoryData = item?.options.filter(
        (option) => option.alias === selectedModel
      );
      if (tempCategoryData.length > 0) {
        const categoryData = tempCategoryData[0].categories;
        customScore = parseInt(
          trustMetricsWeights.reduce(
            (acc, curr) =>
              acc +
              curr.weight *
                (categoryData[curr.name] ? categoryData[curr.name] : 0),
            0
          )
        );
      }
    });
    return customScore;
  };

  const renderModelTrustScoresChart = () => {
    if (!selectedModel || selectedModel === "") {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            Please select a model to view the data.
          </Typography>
        </Box>
      );
    }

    const modelTrustScoreAverage = getAliasScoreData();
    if (modelTrustScoreAverage == null) {
      return (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            height: 250,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            No data available for the chosen model.
          </Typography>
        </Box>
      );
    }

    // Determine the category for the modelTrustScoreAverage
    const categoryObj = categories.find(
      (cat) =>
        modelTrustScoreAverage >= cat.min_score &&
        modelTrustScoreAverage <= cat.max_score
    );
    const color = categoryObj ? categoryObj.color : "#cccccc";

    // Single slice data
    const chartData = [{ label: "AI Trust Score", value: 1, color: color }];

    const StyledText = styled("text")(({ theme }) => ({
      fill: theme.palette.text.primary,
      textAnchor: "middle",
      dominantBaseline: "central",
      fontSize: 20,
    }));

    function PieCenterLabel({ children }) {
      const { width, height, left, top } = useDrawingArea();
      return (
        <StyledText x={left + width / 2} y={top + height / 2}>
          {children}
        </StyledText>
      );
    }

    return (
      <Box
        sx={{
          height: 300,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PieChart
          series={[
            {
              data: chartData,
              innerRadius: 60,
              outerRadius: 100,
              label: () => "",
            },
          ]}
          slotProps={{
            legend: {
              direction: "row",
              position: { vertical: "bottom", horizontal: "middle" },
              itemMarkWidth: 11,
              itemMarkHeight: 11,
            },
          }}
          margin={{ top: 50, bottom: 50, left: 100, right: 100 }}
        >
          <PieCenterLabel>{modelTrustScoreAverage}</PieCenterLabel>
        </PieChart>
      </Box>
    );
  };

  const renderCustomModelTrustScoresChart = () => {
    if (!selectedModel || selectedModel === "") {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            Please select a model to view the data.
          </Typography>
        </Box>
      );
    }

    const modelTrustScoreAverage = generateCustomTrustScore();
    if (modelTrustScoreAverage == null) {
      return (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            height: 250,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            No data available for the chosen model.
          </Typography>
        </Box>
      );
    }

    // Determine the category for the modelTrustScoreAverage
    const categoryObj = categories.find(
      (cat) =>
        modelTrustScoreAverage >= cat.min_score &&
        modelTrustScoreAverage <= cat.max_score
    );
    const color = categoryObj ? categoryObj.color : "#cccccc";

    // Single slice data
    const chartData = [{ label: "AI Trust Score", value: 1, color: color }];

    const StyledText = styled("text")(({ theme }) => ({
      fill: theme.palette.text.primary,
      textAnchor: "middle",
      dominantBaseline: "central",
      fontSize: 20,
    }));

    function PieCenterLabel({ children }) {
      const { width, height, left, top } = useDrawingArea();
      return (
        <StyledText x={left + width / 2} y={top + height / 2}>
          {children}
        </StyledText>
      );
    }

    return (
      <Box
        sx={{
          height: 300,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PieChart
          series={[
            {
              data: chartData,
              innerRadius: 60,
              outerRadius: 100,
              label: () => "",
            },
          ]}
          slotProps={{
            legend: {
              direction: "row",
              position: { vertical: "bottom", horizontal: "middle" },
              itemMarkWidth: 11,
              itemMarkHeight: 11,
            },
          }}
          margin={{ top: 50, bottom: 50, left: 100, right: 100 }}
        >
          <PieCenterLabel>{modelTrustScoreAverage}</PieCenterLabel>
        </PieChart>
      </Box>
    );
  };

  return (
    <Box>
      {/* Models Filter */}
      <>
        <Paper
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Grid container spacing={3} justifyContent="left">
            <Grid item xs={7}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" marginRight={2}>
                AI Endpoints:
                </Typography>
                <FormControl
                  variant="outlined"
                  sx={{ minWidth: 200 }}
                  size="small"
                >
                  <Select
                    value={selectedModel || ""}
                    onChange={handleModelChange}
                    MenuProps={MenuProps}
                  >
                    {loadingAliasCategoryScoresData &&
                    loadingAliasScoresData ? (
                      <MenuItem disabled>Loading...</MenuItem>
                    ) : (
                      generateModelMenuItems()
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </>
      <Grid container spacing={3} justifyContent="center">
        {/* Model Trust Score */}
        <Grid item xs={6}>
          <Paper
            style={{
              margin: 20,
              height: "400px",
              minHeight: "300px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: 8,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: theme.tmryk_background_color,
                padding: 1,
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Typography
                variant="string"
                align="center"
                gutterBottom
                style={{ color: "white", height: "15px" }}
              >
                AI Trust Score<sup style={{ fontSize: "0.6em" }}>TM</sup>
              </Typography>
            </Box>
            {loadingAliasScoresData ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "200px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              renderModelTrustScoresChart()
            )}
          </Paper>
        </Grid>

        {/* Custom Model Trust Score */}
        <Grid item xs={6}>
          <Paper
            style={{
              margin: 20,
              height: "400px",
              minHeight: "300px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: 8,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: theme.tmryk_background_color,
                padding: 1,
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Typography
                variant="string"
                align="center"
                gutterBottom
                style={{ color: "white", height: "15px" }}
              >
                Custom AI Trust Score<sup style={{ fontSize: "0.6em" }}>TM</sup>
              </Typography>
            </Box>
            {loadingAliasScoresData ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "200px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              renderCustomModelTrustScoresChart()
            )}
          </Paper>
        </Grid>

        {/* Featured Model Trust Score Categories */}
        <Grid item xs={12}>
          <Paper
            style={{
              margin: 20,
              marginTop: 5,
              height: "450px",
              minHeight: "300px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: 8,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: theme.tmryk_background_color,
                padding: 1,
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Typography
                variant="string"
                align="center"
                gutterBottom
                style={{ color: "white", height: "15px" }}
              >
                Featured Model Category Scores
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, height: "100%" }}>
              {loadingAliasCategoryScoresData ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "200px",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                renderBarChart()
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ModelChartPanel;
