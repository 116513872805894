import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Alert, Button, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import theme from "../../../theme";
import client from "../../../client";
import { toast } from "react-toastify";

export default function DeleteTenantEndpointModal({
  isOpen,
  setIsOpen,
  endpointId,
  endpointName,
  onDeleteSuccess
}) {
    const handleDelete = async () => {
        if (!endpointId) return;
        try {
          await client.delete(`/api/tenant_pvt_endpoints/${endpointId}`);
          toast.success(`Endpoint "${endpointName}" deleted successfully!`);
          onDeleteSuccess(); // Clear selection and refresh
          setIsOpen(false);
        } catch (err) {
          console.error("Failed to delete endpoint:", err);
          toast.error("Could not delete endpoint. Please try again.");
        }
      };

    const handleClose = () => {
        setIsOpen(false);
    };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        open={isOpen}
        static
        onClose={() => {}}
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="bg-white rounded-lg text-left shadow-xl transform transition-all w-full max-w-md p-6">
                <div className="flex items-center justify-between mb-3">
                  <Typography
                    variant="h6"
                    style={{ color: theme.tmryk_background_color }}
                  >
                    Delete Endpoint?
                  </Typography>
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-white hover:bg-[#031A58] focus:outline-none"
                    onClick={handleClose}
                  >
                    <Close />
                  </button>
                </div>

                <Alert severity="warning" className="mb-3">
                  <strong>
                    Are you sure you want to delete the endpoint{" "}
                    <em>{endpointName}</em>?
                  </strong>
                </Alert>

                <div className="flex justify-end space-x-3">
                  <Button
                    variant="outlined"
                    onClick={handleClose}
                    style={{
                      borderColor: theme.tmryk_background_color,
                      color: theme.tmryk_background_color,
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
