import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Box, Button, TextField, Alert, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import theme from "../../../theme";
import client from "../../../client";
import { toast } from "react-toastify";

export default function CreateOrUpdateTenantEndpointModal({
  isOpen,
  setIsOpen,
  tenantId,
  existingEndpointId, 
  defaultData,        
  onSaveSuccess
}) {
  // If editing, store "oldName" for display or debug.
  const [oldName, setOldName] = useState("");
  const [endpointData, setEndpointData] = useState({
    endpoint_name: "",
    endpoint_url: "",
    header_template: "",
    payload_template: ""
  });
  const [error, setError] = useState("");

  useEffect(() => {
    if (existingEndpointId) {
      // Editing existing endpoint
      if (defaultData?.endpoint_name) {
        setOldName(defaultData.endpoint_name);
      }
      setEndpointData({
        endpoint_name: defaultData?.endpoint_name || "",
        endpoint_url: defaultData?.endpoint_url || "",
        header_template: defaultData?.header_template || "",
        payload_template: defaultData?.payload_template || "",
      });
    } else {
      // Creating new endpoint
      setOldName("");
      setEndpointData({
        endpoint_name: "",
        endpoint_url: "",
        header_template: "",
        payload_template: "",
      });
    }
  }, [existingEndpointId, defaultData]);

  const handleChange = (field, value) => {
    setEndpointData((prev) => ({ ...prev, [field]: value }));
  };

  const handleClose = () => {
    setError("");
    setIsOpen(false);
  };

  const handleSave = async () => {
    setError("");
    const { endpoint_name, endpoint_url, header_template, payload_template } = endpointData;
    if (!endpoint_name || !endpoint_url || !header_template || !payload_template) {
      setError("All fields are required.");
      return;
    }

    try {
      let response;
      if (existingEndpointId) {
        // Update existing endpoint
        response = await client.put(`/api/tenant_pvt_endpoints/${existingEndpointId}`, {
          tenant_id: tenantId,
          endpoint_name,
          endpoint_url,
          header_template,
          payload_template,
        });
        toast.success("Endpoint updated successfully!");
      } else {
        // Create new endpoint
        response = await client.post("/api/tenant_pvt_endpoints", {
          tenant_id: tenantId,
          endpoint_name,
          endpoint_url,
          header_template,
          payload_template,
        });
        toast.success("New endpoint created successfully!");
      }

      // Pass newly created/updated endpoint ID back
      onSaveSuccess(response.data.id);
      setIsOpen(false);
    } catch (e) {
      console.error("Failed to save endpoint:", e);
      setError(e?.response?.data?.detail || "Error saving endpoint.");
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        open={isOpen}
        onClose={() => {}}
        static
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="bg-white rounded-lg text-left shadow-xl transform transition-all w-full max-w-2xl p-6">
                <div className="flex items-center justify-between">
                  <Dialog.Title
                    className="text-lg font-medium"
                    style={{ color: theme.tmryk_background_color }}
                  >
                    {existingEndpointId ? "Update Endpoint" : "Create Endpoint"}
                  </Dialog.Title>
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-white hover:bg-[#031A58] focus:outline-none"
                    onClick={handleClose}
                  >
                    <Close />
                  </button>
                </div>

                <div className="mt-4">
                  {error && (
                    <Alert severity="error" className="mb-4">
                      {error}
                    </Alert>
                  )}

                  <Box display="flex" flexDirection="column" gap={2}>
                    {existingEndpointId && (
                      <TextField
                        label="Old Name (read-only)"
                        value={oldName}
                        InputProps={{ readOnly: true }}
                      />
                    )}

                    <TextField
                      label={
                        existingEndpointId
                          ? "New Endpoint Name"
                          : "Endpoint Name"
                      }
                      helperText="A descriptive name for this endpoint (must be unique)."
                      value={endpointData.endpoint_name}
                      onChange={(e) =>
                        handleChange("endpoint_name", e.target.value)
                      }
                    />

                    <TextField
                      label="Endpoint URL"
                      helperText="E.g. http://localhost:8000/v1/chat/completions"
                      value={endpointData.endpoint_url}
                      onChange={(e) =>
                        handleChange("endpoint_url", e.target.value)
                      }
                    />

                    <TextField
                      label="Header Template (JSON)"
                      helperText={`Example: { "Authorization": "Bearer YOUR_TOKEN", "Content-Type": "application/json" }`}
                      value={endpointData.header_template}
                      multiline
                      rows={2}
                      onChange={(e) =>
                        handleChange("header_template", e.target.value)
                      }
                    />

                    <TextField
                      label="Payload Template (JSON)"
                      helperText={`Example: { "messages": [{ "role": "user", "content": "prompt" }] } (Use "prompt" exactly where user input goes.)`}
                      value={endpointData.payload_template}
                      multiline
                      rows={2}
                      onChange={(e) =>
                        handleChange("payload_template", e.target.value)
                      }
                    />
                  </Box>
                </div>

                <div className="mt-6 flex justify-end space-x-3">
                  <Button
                    variant="outlined"
                    style={{
                      borderColor: theme.tmryk_background_color,
                      color: theme.tmryk_background_color,
                    }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: theme.tmryk_background_color,
                      color: "white",
                    }}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
