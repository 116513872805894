import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  TextField,
  Popover,
  IconButton,
} from "@mui/material";
import theme from "../theme";
import ConfigsPage from "./ConfigsPage";
import ViolationsDashboardModal from "../components/modals/dashboard/ViolationsDashboardModal";
import UsageDashboardModal from "../components/modals/dashboard/UsageDashboardModal";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { FilterList } from "@mui/icons-material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import PolicyChartPanel from "../components/modals/configs/PolicyChartPanel";

function AIPolicyPage({ isActive }) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 365);
    return thirtyDaysAgo;
  });
  const [endDate, setEndDate] = useState(new Date());

  const [anchorEl, setAnchorEl] = useState(null); // State for the filter popover

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  // Define tabs based on isActive
  const tabs = useMemo(() => {
    // Build the label node for "AI Trust Score" with TM in superscript
    const aiTrustLabel = (
      <span>
        AI Trust Score<sup style={{ fontSize: "0.6em" }}>TM</sup>
      </span>
    );

    if (isActive) {
      return [
        { key: "AI_TRUST", label: aiTrustLabel },
        { key: "POLICY_MANAGEMENT", label: "Policy Management" },
        { key: "ALERTS", label: "Alerts" },
        { key: "USAGE_METRICS", label: "Usage Metrics" },
      ];
    } else {
      return [{ key: "AI_TRUST", label: aiTrustLabel }];
    }
  }, [isActive]);

  useEffect(() => {
    if (selectedTab >= tabs.length) {
      setSelectedTab(0);
    }
  }, [tabs, selectedTab]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <AppBar
          position="static"
          style={{ backgroundColor: theme.tmryk_background_color }}
        >
          <Toolbar>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              textColor="inherit"
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "white",
                },
              }}
              sx={{
                "& .MuiTab-root": {
                  color: "white",
                },
                "& .Mui-selected": {
                  borderBottom: "2px solid white",
                },
              }}
            >
              {tabs.map((tabObj, idx) => (
                <Tab key={tabObj.key} label={tabObj.label} />
              ))}
            </Tabs>

            <Box sx={{ flexGrow: 1 }} />

            {/* Filter icon */}
            {tabs[selectedTab].key !== "POLICY_MANAGEMENT" && (
              <IconButton
                color="inherit"
                aria-describedby={id}
                onClick={handleFilterClick}
                sx={{ color: "white" }}
              >
                <FilterList />
              </IconButton>
            )}

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleFilterClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Box
                sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2 }}
              >
                {/* Start Date */}
                <DesktopDatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  maxDate={endDate}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />

                {/* End Date */}
                <DesktopDatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  minDate={startDate}
                  maxDate={new Date()}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Box>
            </Popover>
          </Toolbar>
        </AppBar>

        <Box
          sx={{ padding: 2, backgroundColor: "#f5f5f5", minHeight: "100vh" }}
        >
          {/* Render content based on selectedTab */}
          {tabs[selectedTab].key === "AI_TRUST" && (
            <PolicyChartPanel startDate={startDate} endDate={endDate} />
          )}
          {tabs[selectedTab].key === "POLICY_MANAGEMENT" && <ConfigsPage />}
          {tabs[selectedTab].key === "ALERTS" && isActive && (
            <ViolationsDashboardModal startDate={startDate} endDate={endDate} />
          )}
          {tabs[selectedTab].key === "USAGE_METRICS" && (
            <UsageDashboardModal startDate={startDate} endDate={endDate} />
          )}
        </Box>
      </Box>
    </LocalizationProvider>
  );
}

export default AIPolicyPage;
