import { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Button, Alert, CircularProgress, Tooltip } from '@mui/material';
import { ArrowBack, Close } from '@mui/icons-material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import theme from '../../../theme';
import client from '../../../client';
import llmScanClient from '../../../llmScanClient';

export default function GenerateTrustScoreModal({
  isOpen,
  setIsOpen,
  setIsModalOpen,
  llmType,
  llmData,
  tenantId,
  username,
  accessToken,
  setScans,
  llmEnvName,
  publicTenantId,
  credentialsFile,
  azureEndpoint,
  azureApiVersion,
  azureDeploymentName,
  isActive,
  onRequestAccess,
  engine,
  model
}) {
  const cancelButtonRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(null); 
  const [errorMessage, setErrorMessage] = useState('');

  const [allProbes, setAllProbes] = useState([]);
  const [missingProbes, setMissingProbes] = useState([]);

  const allMissing = missingProbes.length === allProbes.length && allProbes.length > 0;
  const noneMissing = missingProbes.length === 0 && allProbes.length > 0;
  const someMissing = missingProbes.length > 0 && missingProbes.length < allProbes.length;

  const modelName = llmData.llmName || 'this model';

  useEffect(() => {
    if (isOpen) {
      // Always fetch the list of all subprobes from trust_score_probes
      fetchAllProbes();

      // Then fetch "missing" subprobes depending on AI policy, private, or public
      if (llmType === 'ai_policy') {
        // e.g. "AIPolicy" + the aiPolicyType from llmData
        fetchMissingTenantSubprobes(tenantId, 'AIPolicy', llmData.aiPolicyType);
      } else if (llmType === 'private') {
        // e.g. "PrivateEndpoint" + the endpoint name
        // or use llmData.url if that's your unique "name" for private endpoints
        fetchMissingTenantSubprobes(tenantId, 'PrivateEndpoint', llmData.url);
      } else {
        // "public" => standard route
        fetchMissingPublicSubprobes(engine, model);
      }
    }
  }, [isOpen, engine, model]);

  const fetchAllProbes = async () => {
    try {
      const response = await client.get('/get_all_trust_score_sub_probes');
      setAllProbes(response.data);
    } catch (error) {
      console.error('Error fetching all probes:', error);
      setErrorMessage('Failed to fetch all trust score probes.');
    }
  };

  const fetchMissingPublicSubprobes = async (engine, model) => {
    // For public LLM
    try {
      // Example: /get_missing_trust_score_sub_probes?engine=openai&model=gpt-3.5-turbo
      const response = await client.get(
        `/get_missing_trust_score_sub_probes/?engine=${engine}&model=${encodeURIComponent(model)}`
      );
      if (response.data.message === "No missing subprobes found.") {
        setMissingProbes([]);
      } else if (Array.isArray(response.data)) {
        setMissingProbes(response.data);
      } else {
        // fallback
        setMissingProbes([]);
      }
    } catch (error) {
      console.error('Error fetching missing probes (public):', error);
      setErrorMessage('Failed to fetch missing subprobes (public).');
    }
  };

  const fetchMissingTenantSubprobes = async (tid, category, name) => {
    // e.g. category = "AIPolicy" or "PrivateEndpoint"
    // name = the policyName or endpointName
    try {
      // Example:
      // GET /get_tenant_missing_trust_score_sub_probes?tenant_id=21&category=AIPolicy&name=MyPolicy
      const url = `/get_tenant_missing_trust_score_sub_probes?tenant_id=${tid}`
                  + `&category=${encodeURIComponent(category)}`
                  + `&name=${encodeURIComponent(name)}`;
      const response = await client.get(url);

      if (response.data.message === "No missing subprobes found.") {
        setMissingProbes([]);
      } else if (Array.isArray(response.data)) {
        setMissingProbes(response.data);
      } else {
        setMissingProbes([]);
      }
    } catch (error) {
      console.error('Error fetching missing tenant subprobes:', error);
      setErrorMessage('Failed to fetch missing subprobes (tenant).');
    }
  };


  const handleBack = () => {
    setErrorMessage('');
    setIsOpen(false);
    setIsModalOpen(true);
  };

  const handleGenerate = async (probesToRun) => {
    setLoading(true);
    setErrorMessage('');
    try {
      const probeParam = probesToRun.join(',');
      const probeType = 'trust_score';
      let url = '';
      let payload = {};
      const privateEndpointName = llmData.llmName || llmData.url || 'private_endpoint';


      if (llmType === 'ai_policy') {
        // This is the new path for AI Policy
        url = `/command_scan_guardrails?tenant_id=${tenantId}&username=${username}`
           + `&model_type=AIPolicy&model_name=${encodeURIComponent(llmData.aiPolicyType)}`
           + `&probe=${probeParam}&probe_type=${probeType}&taxonomy=owasp`;
  
        url += `&generations=1`;
  
        // or any “guardrails_config” you want to pass
        payload = {
          accessToken: String(accessToken),
          configId: llmData.aiPolicyType, // or whatever your guardrails_config expects
        };
      } else if (llmType === 'private') {
        url = `/command_scan_private?tenant_id=${tenantId}&username=${username}&probe=${probeParam}&probe_type=${probeType}`;
        url += `&generations=1&taxonomy=owasp`;
        url += `&model_name=${encodeURIComponent(privateEndpointName)}`  

        let headerTemplate;
        let payloadTemplate;
        try {
          headerTemplate = JSON.parse(llmData.header_template);
          payloadTemplate = JSON.parse(llmData.payload_template);
        } catch (parseError) {
          setLoading(false);
          setLoadingButton(null);
          setErrorMessage('Invalid JSON format in Header or Payload Template. Please check your input.');
          return;
        }

        payload = {
          url: llmData.url,
          header_template: headerTemplate,
          payload_template: payloadTemplate
        };
      } else {
        const encodedLlmName = encodeURIComponent(llmData.llmName);
        url = `/command_scan?model=${llmData.llmModel}&name=${encodedLlmName}&probe=${probeParam}&probe_type=${probeType}&taxonomy=owasp`;
        url += `&llm_env_name=${llmEnvName || ''}&llm_key=${llmData.apiKey || ''}&tenant_id=${tenantId}&username=${username}`;

        if (llmData.llmModel === 'azure') {
          url += `&azure_endpoint=${encodeURIComponent(azureEndpoint)}`;
          url += `&azure_api_version=${encodeURIComponent(azureApiVersion)}`;
          url += `&azure_deployment_name=${encodeURIComponent(azureDeploymentName)}`;
          url += `&llm_key=${encodeURIComponent(llmData.apiKey)}`;
        }

        url += `&generations=1`;
        payload = null;
      }

      // Handle VertexAI credentials if needed
      if (llmData.llmModel === 'vertexai' && credentialsFile) {
        const formData = new FormData();
        formData.append('credentials_file', credentialsFile, 'credentials.json');
        try {
          await llmScanClient.post(`/upload_vertexai_credentials?tenant_id=${tenantId}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Accept': 'application/json'
            },
            withCredentials: true
          });
        } catch (error) {
          console.error('VertexAI credentials upload error:', error);
          setErrorMessage('Failed to upload VertexAI credentials: ' + (error.response?.data?.detail || error.message));
          setLoading(false);
          setLoadingButton(null);
          return;
        }
      }

      const response = await llmScanClient.post(url, payload);
      if (response.data.error) {
        setErrorMessage(response.data.error);
        setLoading(false);
        setLoadingButton(null);
        return;
      }
      // Update scans list
      const responseList = await llmScanClient.get(`/list_runs/${tenantId}?public_tenant_id=${publicTenantId}`);
      setScans(responseList.data.results);
      setIsOpen(false);
      setIsModalOpen(false);
      setLoading(false);
      setLoadingButton(null);
    } catch (error) {
      console.error('Error generating trust score', error);
      setErrorMessage('There was an error generating the trust score. Please try again.');
      setLoading(false);
      setLoadingButton(null);
    }
  };

  const handleRegenerate = () => {
    setLoadingButton('regenerate');
    handleGenerate(allProbes);
  };

  const handleOptimizedGenerate = () => {
    setLoadingButton('optimized');
    handleGenerate(missingProbes);
  };

  const handleInitialGenerate = () => {
    setLoadingButton('initial');
    handleGenerate(allProbes);
  };

  const getButtonProps = (label, buttonId) => {
    const isLoading = loading && loadingButton === buttonId;
    return {
      variant: 'contained',
      style: {
        backgroundColor: theme.tmryk_background_color,
        color: 'white',
        width: '180px',
        height: '48px',
        textTransform: 'none',
        fontWeight: 'bold',
      },
      disabled: isLoading,
      endIcon: isLoading ? <CircularProgress size={20} color="inherit" /> : null,
      children: isLoading ? `${label}...` : label,
    };
  };

  // Determine the text and tooltips based on conditions
  let modalText = '';
  let regenerateTooltip = `Regenerate Trust Score for ${modelName}`;
  let generateTooltip = `Generate Trust Score for ${modelName}`;
  let optimizedTooltip = `Generate Trust Score for missing probes only: ${modelName}`;

  if (allMissing) {
    // No existing trust score: run all probes
    modalText = `Generating a trust score for chosen model will take approximately 4 hours and consume a significant amount of tokens. Do you want to proceed?`;
  } else if (noneMissing) {
    // Fully existing trust score
    modalText = `Model Trust Score already exists. This will take approximately 4 hours and consume a significant amount of tokens to scan. Are you sure you want to regenerate?`;
  } else if (someMissing) {
    // Partially existing trust score
    modalText = `Model Trust Score partially exists. This will take approximately 4 hours and consume a significant amount of tokens to scan. Optimized Generate will run missing probes only. Are you sure you want to proceed?`;
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setIsOpen}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden flex justify-center items-center ml-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-y-[-100%]"
              enterTo="translate-y-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-[-100%]"
            >
              <Dialog.Panel className="pointer-events-auto w-screen max-w-xl h-full max-h-[30vh] flex flex-col bg-white shadow-xl rounded-lg overflow-y-auto">
                <div className="px-4 pt-6 sm:px-6 bg-white rounded-t-lg">
                  <div className="flex items-start justify-between">
                    <Dialog.Title className="text-lg font-medium" style={{ color: theme.tmryk_background_color }}>
                      {allMissing && 'Generate Trust Score'}
                      {noneMissing && 'Regenerate Trust Score'}
                      {someMissing && 'Generate / Regenerate Trust Score'}
                    </Dialog.Title>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-white hover:bg-[#031A58] focus:outline-none focus:ring-2"
                        onClick={() => setIsOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <Close className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="relative flex-1 px-4 py-6 sm:px-6 overflow-y-auto">
                  {errorMessage && (
                    <Alert severity="error" className="mb-4">
                      {errorMessage}
                    </Alert>
                  )}
                  <p className="text-gray-700 text-base">
                    {modalText}
                  </p>
                </div>
                <div className="flex justify-end space-x-3 p-6 bg-white rounded-b-lg">
                  <Button
                    variant="outlined"
                    onClick={handleBack}
                    startIcon={<ArrowBack />}
                    style={{
                      borderColor: theme.tmryk_background_color,
                      color: theme.tmryk_background_color,
                      width: '150px',
                      height: '48px',
                      textTransform: 'none',
                      fontWeight: 'bold',
                    }}
                  >
                    Back
                  </Button>
                  {isActive ? (
                    <>
                      {noneMissing && (
                        <Tooltip title={regenerateTooltip} arrow>
                          <span>
                            <Button
                              {...getButtonProps('Regenerate', 'regenerate')}
                              onClick={handleRegenerate}
                              style={{
                                ...getButtonProps('Regenerate', 'regenerate').style,
                                width: '150px'
                              }}
                            />
                          </span>
                        </Tooltip>
                      )}
                      {someMissing && (
                        <>
                          <Tooltip title={regenerateTooltip} arrow>
                            <span>
                              <Button
                                {...getButtonProps('Regenerate', 'regenerate')}
                                onClick={handleRegenerate}
                                style={{
                                  ...getButtonProps('Regenerate', 'regenerate').style,
                                  width: '150px'
                                }}
                              />
                            </span>
                          </Tooltip>
                          <Tooltip title={optimizedTooltip} arrow>
                            <span>
                              <Button
                                {...getButtonProps('Optimized Generate', 'optimized')}
                                onClick={handleOptimizedGenerate}
                                style={{
                                  ...getButtonProps('Optimized Generate', 'optimized').style,
                                  width: '180px'
                                }}
                              />
                            </span>
                          </Tooltip>
                        </>
                      )}
                      {allMissing && (
                        <Tooltip title={generateTooltip} arrow>
                          <span>
                            <Button
                              {...getButtonProps('Generate Trust Score', 'initial')}
                              onClick={handleInitialGenerate}
                              style={{
                                ...getButtonProps('Generate Trust Score', 'initial').style,
                                width: '180px'
                              }}
                            />
                          </span>
                        </Tooltip>
                      )}
                    </>
                  ) : (
                    <Tooltip title={`Request Access to run scans for ${modelName}`} arrow>
                      <span>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: theme.tmryk_background_color,
                            color: 'white',
                            width: '180px',
                            height: '48px',
                            textTransform: 'none',
                            fontWeight: 'bold',
                          }}
                          startIcon={<LockOpenIcon />}
                          onClick={onRequestAccess}
                        >
                          Request Access
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
