import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { LineChart, PieChart, useDrawingArea } from "@mui/x-charts";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { styled } from "@mui/material/styles";
import client from "../../../client";
import theme from "../../../theme";
import { DEFAULT_CATEGORIES_SETTING } from "../../../constants/supportedModels";

const PolicyChartPanel = ({ startDate, endDate }) => {
  // Alias category scores data
  const [realTimeTrustScoreData, setRealTimeTrustScoreData] = useState(null);
  const [lineChartData, setLineChartData] = useState([]);
  const [loadingDynamicScoreConfigs, setLoadingDynamicScoreConfigs] =
    useState(true);
  const [dynamicScoreConfigs, setDynamicScoreConfigs] = useState([]);
  const [selectedAiPolicy, setSelectedAiPolicy] = useState("All");
  const [isLoading, setIsLoading] = useState(true);

  const [categories, setCategories] = useState(
    DEFAULT_CATEGORIES_SETTING.reduce((acc, curr) => {
      acc[curr.name] = curr.color; // Set name as key, color as value
      return acc;
    }, {})
  );

  useEffect(() => {
    fetchDynamicScoreConfigs();
    fetchTenantCategory();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchRealTimeTrustScoreData();
    fetchTrendData();
  }, [startDate, endDate, selectedAiPolicy]);

  const fetchDynamicScoreConfigs = async () => {
    try {
      const response = await client.get("/api/dynamic_scores/configs");
      setDynamicScoreConfigs(response.data);
      setLoadingDynamicScoreConfigs(false);
    } catch (error) {
      console.error("Error fetching dynamic score configs:", error);
      setLoadingDynamicScoreConfigs(false);
    }
  };

  // Fetch tenant alias scores data (if needed for other charts)
  const fetchTenantCategory = async () => {
    try {
      const response_category_settings = await client.get(`/category_settings`);

      if (response_category_settings.data?.categories) {
        setCategories(
          response_category_settings.data?.categories.reduce((acc, curr) => {
            acc[curr.name] = curr.color; // Set name as key, color as value
            return acc;
          }, {})
        );
      }
    } catch (error) {
      console.error("Error fetching tenant alias scores data:", error);
    }
  };

  const fetchRealTimeTrustScoreData = () => {
    client
      .get("/real_time_trust_scores", {
        params: {
          start_date: startDate.toISOString().split("T")[0],
          end_date: endDate.toISOString().split("T")[0],
          ai_policy:
            selectedAiPolicy === "All" ? "" : selectedAiPolicy || undefined,
        },
      })
      .then((response) => {
        const data = response.data;
        if (
          data &&
          (data.average_trust_score != null || data.average_score != null) &&
          ["excellent", "good", "fair", "poor", "very_poor"].some(
            (key) => typeof data[key] === "number" && data[key] > 0
          )
        ) {
          setRealTimeTrustScoreData(data);
        } else {
          setRealTimeTrustScoreData(null);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching real-time trust score data:", error);
        setRealTimeTrustScoreData(null);
        setIsLoading(false);
      });
  };

  const fetchTrendData = () => {
    client
      .get("/trend_data", {
        params: {
          start_date: startDate.toISOString().split("T")[0],
          end_date: endDate.toISOString().split("T")[0],
          interval: "week",
          ai_policy:
            selectedAiPolicy === "All" ? "" : selectedAiPolicy || undefined,
        },
      })
      .then((response) => {
        const trendData = response.data.ai_trust_score.map((item) => ({
          x: item.period,
          y: item.average_score,
        }));
        setLineChartData([
          { name: "AI Trust Score", data: trendData, color: "#4caf50" },
        ]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching trend data:", error);
        setLineChartData([]);
        setIsLoading(false);
      });
  };

  const handleAiPolicyChange = (event) => {
    setSelectedAiPolicy(event.target.value);
  };

  const renderLineChart = () => {
    if (lineChartData.length > 0) {
      const xData = lineChartData[0].data.map((item) => item.x); // Dates
      const yData = lineChartData[0].data.map((item) => item.y); // Scores

      return (
        <Box sx={{ width: "100%", height: "100%", flexGrow: 1 }}>
          <LineChart
            xAxis={[{ data: xData, label: "Period", scaleType: "point" }]}
            yAxis={[
              {
                label: "AI Trust Score",
                min: 0,
                max: 1000,
                ticks: [0, 250, 500, 750, 1000],
              },
            ]}
            series={[
              {
                data: yData,
                label: "AI Trust Score",
                color: lineChartData[0].color,
                strokeWidth: 2,
              },
            ]}
            tooltip={{ show: true }}
            sx={{
              width: "100%",
              height: "100%",
              paddingBottom: "60px",
              [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
                transform: "translateX(-20px)",
              },
            }}
          />
        </Box>
      );
    }
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">
          No data available for the selected time range/policy.
        </Typography>
      </Box>
    );
  };

  const renderRealTimePieChart = () => {
    if (!realTimeTrustScoreData) {
      return (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            No data available for the selected time range/policy.
          </Typography>
        </Box>
      );
    }

    const averageScore =
      realTimeTrustScoreData.average_trust_score !== undefined
        ? realTimeTrustScoreData.average_trust_score
        : realTimeTrustScoreData.average_score;

    const chartData = [
      {
        label: "Excellent",
        value: realTimeTrustScoreData.excellent,
        color: categories["Excellent"] ?? "#448448",
      },
      {
        label: "Good",
        value: realTimeTrustScoreData.good,
        color: categories["Good"] ?? "#50C878",
      },
      {
        label: "Fair",
        value: realTimeTrustScoreData.fair,
        color: categories["Fair"] ?? "#ffdd33",
      },
      {
        label: "Poor",
        value: realTimeTrustScoreData.poor,
        color: categories["Poor"] ?? "#FFBF00",
      },
      {
        label: "Very Poor",
        value: realTimeTrustScoreData.very_poor,
        color: categories["Very Poor"] ?? "#ff4637",
      },
    ];

    const StyledText = styled("text")(({ theme }) => ({
      fill: theme.palette.text.primary,
      textAnchor: "middle",
      dominantBaseline: "central",
      fontSize: 20,
    }));

    function PieCenterLabel({ children }) {
      const { width, height, left, top } = useDrawingArea();
      return (
        <StyledText x={left + width / 2} y={top + height / 2}>
          {children}
        </StyledText>
      );
    }

    return (
      <Box
        sx={{
          height: 300,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PieChart
          series={[
            {
              data: chartData,
              innerRadius: 60,
              outerRadius: 100,
              label: () => "",
            },
          ]}
          slotProps={{
            legend: {
              direction: "row",
              position: { vertical: "bottom", horizontal: "middle" },
              itemMarkWidth: 11,
              itemMarkHeight: 11,
            },
          }}
          margin={{ top: 50, bottom: 50, left: 100, right: 100 }}
        >
          <PieCenterLabel>{averageScore}</PieCenterLabel>
        </PieChart>
      </Box>
    );
  };

  return (
    <Box>
      {/* AI Policy Filters */}
      <Paper
        sx={{
          padding: 2,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Grid container spacing={3} justifyContent="left">
          <Grid item xs={5}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <Typography variant="body1" marginRight={2}>
                AI Policy:
              </Typography>
              <FormControl
                variant="outlined"
                sx={{ minWidth: 200 }}
                size="small"
              >
                <Select
                  value={selectedAiPolicy}
                  onChange={handleAiPolicyChange}
                >
                  <MenuItem key="all-policy" value="All">
                    All
                  </MenuItem>
                  {loadingDynamicScoreConfigs ? (
                    <MenuItem disabled>Loading...</MenuItem>
                  ) : (
                    dynamicScoreConfigs.map((config) => (
                      <MenuItem key={config} value={config}>
                        {config}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Grid container spacing={3} justifyContent="center">
        {/* Realtime Trust Score */}
        <Grid item xs={6}>
          <Paper
            style={{
              margin: 20,
              height: "400px",
              minHeight: "300px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: 8,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: theme.tmryk_background_color,
                padding: 1,
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Typography
                variant="string"
                align="center"
                gutterBottom
                style={{ color: "white", height: "15px" }}
              >
                Real Time AI Trust Score
                <sup style={{ fontSize: "0.6em" }}>TM</sup>
              </Typography>
            </Box>
            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "200px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              renderRealTimePieChart()
            )}
          </Paper>
        </Grid>

        {/* Trust Score Trend Over Time */}
        <Grid item xs={6}>
          <Paper
            style={{
              margin: 20,
              height: "400px",
              minHeight: "300px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: 8,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: theme.tmryk_background_color,
                padding: 1,
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Typography
                variant="string"
                align="center"
                gutterBottom
                style={{ color: "white", height: "15px" }}
              >
                AI Trust Score<sup style={{ fontSize: "0.6em" }}>TM</sup> Trend
                Over Time
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, height: "100%" }}>
              {isLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "200px",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                renderLineChart()
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PolicyChartPanel;
