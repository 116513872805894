import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Tooltip,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  CircularProgress,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  TableContainer,
  Paper,
} from "@mui/material";
import client from "../client";
import theme from "../theme";
import AddConfigModal from "../components/modals/configs/AddConfigModal";
import UpdatePolicyModal from "../components/modals/configs/UpdatePolicyModal";
import DeleteConfigModal from "../components/modals/configs/DeleteConfigModal";
import DeleteConfigFileModal from "../components/modals/configs/DeleteConfigFileModal";
import AddFileConfigModal from "../components/modals/configs/AddFileConfigModal";
import AddRecordModal from "../components/modals/configs/AddRecordModal";
import ViewListModal from "../components/common/ViewListModal";
import DeleteCustomListModal from "../components/modals/configs/DeleteCustomListModal";
import EditCustomListModal from "../components/modals/configs/EditCustomListModal";
import ImportPolicyModal from "../components/modals/configs/ImportPolicyModal";
import ExportPolicyModal from "../components/modals/configs/ExportPolicyModal";
import { ActionDotIcon } from "../assets/images/icons/ActionDotIcon/ActionDotIcon";
import AddIcon from "@mui/icons-material/Add";
import SelectFilesButtonComponent from "../components/modals/configs/SelectFilesButtonComponent";
import SelectFilesListComponent from "../components/modals/configs/SelectFilesListComponent";
import { toast } from "react-toastify";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { python } from "@codemirror/lang-python";
import { yaml } from "@codemirror/lang-yaml";
import { oneDark } from "@codemirror/theme-one-dark";
import LLM_TYPES from "../constants/supportedModels";

function ConfigsPage() {
  const [configs, setConfigs] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteFileModalOpen, setIsDeleteFileModalOpen] = useState(false);
  const [isAddFileModalOpen, setIsAddFileModalOpen] = useState(false);
  const [isAddRecordModalOpen, setIsAddRecordModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isDeleteCustomListModalOpen, setIsDeleteCustomListModalOpen] =
    useState(false);
  const [isEditCustomListModalOpen, setIsEditCustomListModalOpen] =
    useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [selectedList, setSelectedList] = useState(null);
  const [postModalAction, setPostModalAction] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [fileNames, setFileNames] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFileContent, setSelectedFileContent] = useState("");
  const [originalFileContent, setOriginalFileContent] = useState("");
  const [filesContent, setFilesContent] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [promptMessage, setPromptMessage] = useState("");
  const [apiResponse, setApiResponse] = useState("");
  const [generatedPrompts, setGeneratedPrompts] = useState([]);
  const [selectedGeneratedPrompt, setSelectedGeneratedPrompt] = useState(null);
  const [selectedConfigId, setSelectedConfigId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [originalPrompt, setOriginalPrompt] = useState("");
  const [isChecking, setIsChecking] = useState(false);
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [showTestPolicyError, setShowTestPolicyError] = useState({});
  const [readOnly, setReadOnly] = useState(false);
  const [runDetails, setRunDetails] = useState(null);

  const formRef = useRef(null);

  const fetchConfigs = async () => {
    setLoading(true);
    try {
      const response = await client.get("/v1/rails/configs");
      setConfigs(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConfigs();
  }, []);

  const fetchUpdatedList = async () => {
    try {
      const response = await client.get("/custom_suite_list");
      return response.data;
    } catch (error) {
      toast.error("Error fetching custom suites");
      return [];
    }
  };

  const handleConfigUpdated = async (updatedConfig) => {
    await fetchConfigs();
    setSelectedConfig(updatedConfig);
    setIsUpdateModalOpen(false);
  };

  useEffect(() => {
    if (!isAddRecordModalOpen && postModalAction) {
      if (postModalAction.type === "view") {
        setIsViewModalOpen(true);
      } else if (postModalAction.type === "delete") {
        setIsDeleteCustomListModalOpen(true);
      } else if (postModalAction.type === "edit") {
        setIsEditCustomListModalOpen(true);
      }
      setPostModalAction(null);
    }
  }, [isAddRecordModalOpen, postModalAction]);

  const handleMenuClick = (event, config) => {
    setAnchorEl(event.currentTarget);
    setSelectedConfigId(config.id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    const config = configs.find((config) => config.id === selectedConfigId);
    setSelectedConfig(config);
    setSelectedTab(0);
    handleMenuClose();
  };

  const handleDeleteClick = () => {
    const config = configs.find((config) => config.id === selectedConfigId);
    setSelectedConfig(config);
    setIsDeleteModalOpen(true);
    handleMenuClose();
  };
  const handleExportClick = () => {
    const config = configs.find((config) => config.id === selectedConfigId);
    setSelectedConfig(config);
    setIsExportModalOpen(true);
    handleMenuClose();
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleNewConfigAdded = async (newConfig) => {
    await fetchConfigs();
    setIsAddModalOpen(false);
    setSelectedConfig(newConfig);
  };

  const fetchFileContents = async (configId) => {
    try {
      const response = await client.get(`/read_config?config_name=${configId}`);
      setFilesContent(response.data);
      const fileNames = Object.keys(response.data);
      setFileNames(fileNames);
      setSelectedFileName("");
      setSelectedFileContent("");
      setOriginalFileContent("");
    } catch (error) {
      console.error("Error fetching file contents:", error);
      toast.error("Error fetching file contents");
    }
  };

  useEffect(() => {
    if (selectedConfig) {
      fetchFileContents(selectedConfig.id);
    } else {
      setFileNames([]);
      setSelectedFileName("");
      setSelectedFileContent("");
      setOriginalFileContent("");
    }
  }, [selectedConfig]);

  const selectFile = (fileName) => {
    setSelectedFileName(fileName);
    setSelectedFileContent(fileName ? filesContent[fileName] : "");
    setOriginalFileContent(fileName ? filesContent[fileName] : "");

    if (fileName === "models.yml" || fileName === "models.yaml") {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
  };

  const saveEditedContent = async () => {
    const updateConfigEndpoint = `/edit_config?config_name=${
      selectedConfig.id
    }&file_name=${selectedFileName}&content=${encodeURIComponent(
      selectedFileContent
    )}`;
    try {
      await client.post(updateConfigEndpoint);
      toast.success("File content updated successfully!");
      setFilesContent((prev) => ({
        ...prev,
        [selectedFileName]: selectedFileContent,
      }));
      setOriginalFileContent(selectedFileContent);
    } catch (error) {
      console.error("Error saving file content:", error);
      toast.error(
        "Error saving file content: " +
          (error.response?.data?.detail || error.message)
      );
    }
  };

  const deleteSelectedFile = () => {
    setIsDeleteFileModalOpen(true);
  };

  const getFileMode = (fileName) => {
    if (fileName.endsWith(".co")) return javascript();
    if (fileName.endsWith(".yml") || fileName.endsWith(".yaml")) return yaml();
    if (fileName.endsWith(".py")) return python();
    return javascript();
  };

  const addFilesToKnowledgeBase = async () => {
    if (!selectedConfig) {
      toast.error("Please select a Policy");
      return;
    }
    if (formRef.current) {
      const formData = new FormData(formRef.current);
      formData.append("config_name", selectedConfig ? selectedConfig.id : "");
      try {
        await client.post("/update_kb", formData);
        toast.success("Knowledge base was updated!");
        setSelectedFiles([]);
        if (selectedConfig) {
          await fetchFileContents(selectedConfig.id);
        }
      } catch (error) {
        console.error("Error updating knowledge base:", error);
        toast.error("Error updating knowledge base");
      }
    }
  };

  const handleSubmit = async () => {
    setRunDetails(null); // Reset run details
    setShowTestPolicyError({});

    if (!selectedConfig) {
      toast.error("Select a Policy");
      return;
    }
    if (!promptMessage.trim()) {
      toast.error("Please enter a prompt to receive a response.");
      return;
    }

    setApiResponse("");
    setIsSubmitting(true);

    try {
      const response = await client.post("/v1/chat/completions", {
        config_id: selectedConfig.id,
        messages: [{ role: "user", content: promptMessage }],
        stream: false,
      });

      const botMessage = response.data.messages[0];
      const content = botMessage.content;

      const bot_message_log_summary =
        response.data.log || "No log summary available";
      const general_stats =
        bot_message_log_summary
          .split("# General stats")[1]
          ?.split("# Detailed stats")[0]
          ?.trim() || "No general stats available";
      const detailed_stats =
        bot_message_log_summary.split("# Detailed stats")[1]?.trim() ||
        "No detailed stats available";

      const runDetailsFromApi = {
        trust_score: response.data.metrics?.trust_score || "N/A",
        model_score: response.data.metrics?.model_score || "N/A",
        bot_message_log_summary,
        general_stats,
        detailed_stats,
        violation: response.data.metrics?.violation || false,
        jailbreak_detection:
          response.data.metrics?.jailbreak_detection || false,
        bias_detection: response.data.metrics?.bias_detection || false,
        bias_score: response.data.metrics?.bias_score || {
          input: 0.0,
          output: 0.0,
        },
        llama_guard_categories: response.data.metrics
          ?.llama_guard_categories || { input: "", output: "" },
        hallucination_score: response.data.metrics?.hallucination_score || 0.0,
        information: response.data.metrics?.information || [],
      };

      setApiResponse(content);
      setRunDetails(runDetailsFromApi);

      toast.success("Prompt submitted successfully!");
    } catch (error) {
      if (error.response?.data?.detail) {
        const errorMsg = error.response.data.detail;
        if (typeof errorMsg === "string") {
          console.error("Failed to submit prompt:", errorMsg);
          toast.error("Failed to submit prompt:" + errorMsg);
        }
        if (
          typeof errorMsg === "object" &&
          errorMsg.hasOwnProperty("message")
        ) {
          toast.error("Failed to submit prompt.");
          setShowTestPolicyError(errorMsg);
        }
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCheckClick = async () => {
    if (!selectedConfig || !selectedFileName) return;

    setIsChecking(true);
    setError("");
    setShowError(false); // Reset error display
    setShowTestPolicyError({});

    // If it's a .co file, run the current Colang validation logic
    if (selectedFileName.endsWith(".co")) {
      // Save the content before validation
      await saveEditedContent();

      try {
        const response = await client.post(
          `/colang_parser?config_name=${encodeURIComponent(
            selectedConfig.id
          )}&file_name=${encodeURIComponent(selectedFileName)}`
        );

        if (response.status === 200) {
          toast.success("Colang file parsed successfully!");
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.detail
        ) {
          // Check if `detail` is an array and handle it accordingly
          const errorMessage = Array.isArray(error.response.data.detail)
            ? error.response.data.detail.map((err) => err.msg).join(", ")
            : error.response.data.detail; // If it's not an array, use it directly

          setError(errorMessage); // Set the error as a string
          setShowError(true);
        } else {
          setError(
            "Error on validating Colang File: " +
              (error?.message || "Unknown error occurred.")
          ); // Set the error as a string for display
          setShowError(true);
        }
      } finally {
        setIsChecking(false);
      }
    }

    try {
      // If it's a .yaml or .yml file, run the API key checker logic
      if (!selectedFileName.endsWith(".co")) {
        const selectedConfigEngine = await client.get(
          `/read_model_engine?config_name=${selectedConfig.id}`
        );

        // Checking that engine should be from supported engines
        if (!LLM_TYPES[selectedConfigEngine.data?.engine]) {
          setError(
            `Please provide valid engine. Found '${selectedConfigEngine.data.engine}'`
          ); // Set the error as a string
          setShowError(true);
        } else if (
          !LLM_TYPES[selectedConfigEngine.data.engine]?.models.includes(
            selectedConfigEngine.data.model.trim()
          )
        ) {
          // Checking that model should be from the respective engine
          setError(
            `Please provide valid model for engine '${selectedConfigEngine.data.engine}'`
          ); // Set the error as a string
          setShowError(true);
        } else if (
          LLM_TYPES[selectedConfigEngine.data.engine]?.requiredToAddKey
        ) {
          // For the model requires an API Keys, Checking for the API keys exists or not
          // Send the request to check the API key
          const response = await client.post(
            `/check_api_key?config_id=${encodeURIComponent(selectedConfig.id)}`
          );
          // Check if the response is successful and contains the "API key exists" message
          if (
            response.status === 200 &&
            response.data.message === "API key exists"
          ) {
            toast.success("API key exists for the selected Policy.");
          } else {
            setError("API key not found for the selected Policy."); // Set the error as a string
            setShowError(true);
          }
        } else {
          // For the model does not requires an API Keys
          toast.success("API Key not required for this LLM.");
        }
      }
    } catch (error) {
      // Handle any errors during the request
      if (error.response && error.response.data && error.response.data.detail) {
        const errorMessage = Array.isArray(error.response.data.detail)
          ? error.response.data.detail.map((err) => err.msg).join(", ")
          : error.response.data.detail; // Handle as array or string

        setError(errorMessage); // Set the error as a string for display
        setShowError(true);
      } else {
        setError(
          "Error checking API key: " +
            (error?.message || "Unknown error occurred.")
        ); // Set the error as a string for display
        setShowError(true);
      }
    } finally {
      setIsChecking(false); // Reset the loading state
    }
  };

  useEffect(() => {
    setError("");
    setShowError(false);
    setShowTestPolicyError({});
  }, [selectedConfig, selectedFileName]);

  const handleGenerateClick = async () => {
    setShowTestPolicyError({});
    if (!promptMessage.trim()) {
      toast.error("Please enter a prompt to generate variations");
      return;
    }

    setIsGenerating(true);
    setOriginalPrompt(promptMessage);
    setGeneratedPrompts([]);

    try {
      const response = await client.post(
        `/generate_prompt?user_prompt=${encodeURIComponent(promptMessage)}`
      );
      setGeneratedPrompts(response.data.variations);
      setSelectedGeneratedPrompt(null);
      toast.success("Prompt variations generated successfully!");
    } catch (error) {
      console.error("Failed to generate prompt variations:", error);
      toast.error("Failed to generate prompt variations: " + error.message);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleGeneratedPromptClick = (index) => {
    if (selectedGeneratedPrompt === index) {
      setSelectedGeneratedPrompt(null);
      setPromptMessage(originalPrompt);
    } else {
      setSelectedGeneratedPrompt(index);
      setPromptMessage(generatedPrompts[index]);
    }
  };

  const handleClearResponse = () => {
    setApiResponse("");
    setRunDetails(null);
  };

  const renderFileSelector = () => {
    if (fileNames.length < 3) {
      return (
        <div style={{ display: "flex", overflowX: "auto" }}>
          {fileNames.map((fileName, index) => (
            <button
              key={index}
              onClick={() => selectFile(fileName)}
              style={{
                background:
                  selectedFileName === fileName
                    ? theme.tmryk_background_color
                    : "white",
                color: selectedFileName === fileName ? "white" : "black",
                fontWeight: selectedFileName === fileName ? "bold" : "normal",
                margin: "0 5px",
                padding: "5px 5px",
                border: "1px solid gray",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              {getFileLabel(fileName)}
            </button>
          ))}
        </div>
      );
    } else {
      return (
        <select
          className="bg-gray-50 border border-gray-300 rounded-md text-gray-700 leading-tight focus:outline-none focus:border-[#031A58]"
          style={{ padding: "5px 5px" }}
          value={selectedFileName}
          onChange={(e) => selectFile(e.target.value)}
        >
          <option value="">Select a File</option>
          {fileNames.map((fileName, index) => (
            <option key={index} value={fileName}>
              {getFileLabel(fileName)}
            </option>
          ))}
        </select>
      );
    }
  };

  const getFileLabel = (fileName) => {
    if (fileName.endsWith(".co")) {
      return `Colang (${fileName.replace(".co", "")})`;
    } else if (fileName.endsWith(".yml") || fileName.endsWith(".yaml")) {
      return `Configuration (${fileName.replace(/\.(yml|yaml)$/, "")})`;
    } else {
      return fileName;
    }
  };

  const renderEditConfigTab = () => (
    <div className="flex flex-col w-full pr-8">
      {selectedConfig && (
        <div className="flex mt-4 justify-between items-center">
          <Typography variant="h6" color={theme.tmryk_black_text_color}>
            Current Selected Policy: {selectedConfig.id}
          </Typography>
          {/* Update Policy Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsUpdateModalOpen(true)}
            sx={{
              backgroundColor: theme.tmryk_background_color,
              "&:hover": { backgroundColor: "#172E6C" },
            }}
          >
            Update Policy
          </Button>
        </div>
      )}

      <Typography
        variant="body2"
        color={theme.tmryk_black_text_color}
        sx={{ marginTop: 2, marginBottom: 2 }}
      >
        Click on a checkbox to select a Policy from the list below. Use this
        panel to modify Colang or configuration settings for models. Once edits
        are complete, save and test the changes using the Test Policy panel.
      </Typography>

      {/* Error Alert */}
      {showError && (
        <div className="w-full mb-4 mt-2 border border-b dark:border-red-600 rounded-[4px]">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#5f2120" }} />}
              aria-controls="validate-error-details-header"
              id="validate-error-details-header"
              style={{
                backgroundColor: "#fdeded",
                color: "#5f2120",
                borderRadius: "4px",
              }}
            >
              An error occurred during validation. Please expand to view more
              details.
            </AccordionSummary>
            <AccordionDetails
              style={{
                backgroundColor: "white",
                color: "#5f2120",
                borderRadius: "4px",
              }}
            >
              {error}
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      {/* File Edit Section */}
      <Box
        sx={{
          marginTop: 2,
          padding: 2,
          backgroundColor: "white",
          border: "1px solid #e0e0e0",
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 1,
          }}
        >
          <Typography variant="subtitle1">Edit Selected File</Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {renderFileSelector()}
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsAddFileModalOpen(true)}
              sx={{
                backgroundColor: theme.tmryk_background_color,
                "&:hover": { backgroundColor: "#172E6C" },
              }}
            >
              Add File
            </Button>
          </Box>
        </Box>

        <CodeMirror
          value={selectedFileContent}
          height="300px"
          extensions={[getFileMode(selectedFileName)]}
          theme={oneDark}
          onChange={(value) => setSelectedFileContent(value)}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            marginTop: 2,
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCheckClick}
            disabled={!selectedFileName || isChecking}
          >
            {isChecking ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Validate"
            )}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={saveEditedContent}
            disabled={!selectedFileName}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={deleteSelectedFile}
            disabled={!selectedFileName || readOnly}
          >
            Delete
          </Button>
        </Box>
      </Box>

      {/* Knowledge Base Section */}
      <Typography
        variant="h6"
        sx={{
          marginTop: 4,
          marginBottom: 2,
          color: theme.tmryk_black_text_color,
        }}
      >
        Knowledge Base Upload
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Upload Markdown or text files only. Each file should not exceed 5MB.
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2, marginTop: 2 }}>
        <SelectFilesButtonComponent
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          ref={formRef}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={addFilesToKnowledgeBase}
          disabled={selectedFiles.length === 0}
        >
          Upload
        </Button>
      </Box>
      <SelectFilesListComponent selectedFiles={selectedFiles} />
    </div>
  );

  const renderTestConfigTab = () => (
    <div className="flex flex-col w-full pr-8">
      {selectedConfig && (
        <div className="flex mt-4 mb-2">
          <p
            className={`text-lg font-medium text-[${theme.tmryk_black_text_color}]`}
          >
            Current Selected Policy: {selectedConfig.id}
          </p>
        </div>
      )}
      <div className="flex mb-4 mt-2">
        <p className={`text-xs text-[${theme.tmryk_black_text_color}]`}>
          Try out the Policy after your changes have been saved.
        </p>
      </div>
      {showTestPolicyError?.message && (
        <div className="w-full mb-4 mt-2 border border-b dark:border-red-600 rounded-[4px]">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#5f2120" }} />}
              aria-controls="error-details-header"
              id="error-details-header"
              style={{
                backgroundColor: "#fdeded",
                color: "#5f2120",
                borderRadius: "4px",
              }}
            >
              An error has occurred. Please expand to view additional details.
            </AccordionSummary>
            <AccordionDetails
              style={{
                backgroundColor: "white",
                color: "#5f2120",
                borderRadius: "4px",
              }}
            >
              {showTestPolicyError?.error}
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      <div className="w-full mb-2 border border-white-200 rounded-md bg-gray-50 dark:bg-white-700 dark:border-gray-600">
        <div
          className="flex items-center justify-between px-3 py-2 border-b dark:border-gray-600"
          style={{
            backgroundColor: theme.tmryk_background_color,
            color: "white",
          }}
        >
          <div className="flex items-center">
            <span>Prompt</span>
          </div>

          {generatedPrompts.length > 0 && (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                gap: "10px",
              }}
            >
              {generatedPrompts.map((_, index) => (
                <Button
                  key={index}
                  variant="contained"
                  style={{
                    backgroundColor:
                      selectedGeneratedPrompt === index
                        ? theme.tmryk_background_color
                        : "white",
                    color:
                      selectedGeneratedPrompt === index
                        ? "white"
                        : theme.tmryk_background_color,
                    border:
                      selectedGeneratedPrompt === index
                        ? "1px solid white"
                        : "none",
                    padding: "6px 16px",
                    minWidth: "100px",
                  }}
                  onClick={() => handleGeneratedPromptClick(index)}
                >
                  Prompt {index + 1}
                </Button>
              ))}
            </div>
          )}
        </div>
        <div className="px-4 py-2 bg-white rounded-b-md dark:bg-white-800 flex flex-col">
          <textarea
            rows="4"
            className="block w-full text-sm mb-2 text-gray-800 bg-white border-0 dark:bg-black-800 dark:text-black dark:placeholder-gray-400 focus:outline-none"
            placeholder="Please enter your prompt here and click on submit."
            value={promptMessage}
            onChange={(e) => setPromptMessage(e.target.value)}
          />
          <div className="flex justify-end">
            <Button
              variant="contained"
              style={{
                backgroundColor: promptMessage.trim()
                  ? theme.tmryk_background_color
                  : "rgba(0, 0, 0, 0.12)", // Same background as disabled Record button
                color: promptMessage.trim() ? "white" : "rgba(0, 0, 0, 0.26)", // Same color as disabled Record button
                fontWeight: "bold",
                marginRight: "10px",
              }}
              onClick={handleGenerateClick}
              disabled={!promptMessage.trim() || isGenerating}
            >
              {isGenerating ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Generate"
              )}
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: theme.tmryk_background_color,
                color: "white",
              }}
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </div>
      </div>
      <div className="w-full mt-4 border border-white-200 rounded-md bg-gray-50 dark:bg-white-700 dark:border-gray-600">
        <div
          className="flex items-center justify-between px-3 py-2 border-b dark:border-gray-600"
          style={{
            backgroundColor: theme.tmryk_background_color,
            color: "white",
          }}
        >
          <div className="flex items-center">
            <span>Response</span>
          </div>
        </div>
        <div className="px-4 py-2 bg-white rounded-b-md dark:bg-white-800">
          <textarea
            rows="10"
            className="block w-full px-0 text-sm mb-2 text-white-800 bg-white border-0 dark:bg-black-800 focus:outline-none dark:text-black dark:placeholder-gray-400"
            placeholder="The generated response will appear here once you click submit"
            value={apiResponse}
            readOnly
          />
          <div className="flex justify-end">
            <Button
              variant="contained"
              style={{
                backgroundColor: theme.tmryk_background_color,
                color: "white",
              }}
              onClick={handleClearResponse}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: apiResponse
                  ? theme.tmryk_background_color
                  : "rgba(0, 0, 0, 0.12)",
                color: apiResponse ? "white" : "rgba(0, 0, 0, 0.26)",
                fontWeight: "bold",
                marginLeft: "10px",
              }}
              onClick={() => setIsAddRecordModalOpen(true)}
              disabled={!apiResponse}
            >
              Record
            </Button>
          </div>
        </div>
      </div>
      {runDetails && (
        <div className="w-full mt-4">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              aria-controls="code-details-content"
              id="code-details-header"
              style={{
                backgroundColor: theme.tmryk_background_color,
                color: "white",
                borderRadius: "4px",
              }}
            >
              <Typography>
                Trust Metrics
                {runDetails?.trust_score !== undefined && (
                  <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                    [Trust Score: {runDetails.trust_score}]
                  </span>
                )}
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "white" }}>
              {/* Display the code details here */}
              {renderCodeDetails()}
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </div>
  );

  const renderCodeDetails = () => {
    if (!runDetails) {
      return (
        <Typography variant="body2" color="textSecondary">
          No run details available. Submit a prompt to see the details.
        </Typography>
      );
    }

    const {
      bot_message_log_summary,
      general_stats,
      detailed_stats,
      violation,
      jailbreak_detection,
      bias_score,
      llama_guard_categories,
      hallucination_score,
      trust_score,
      model_score,
      information,
    } = runDetails;

    const transform_result = {
      "Trust Score": trust_score || "N/A",
      "Model Score": model_score,
      Violation: violation ? "Yes" : "No",
      "Jailbreak Detection": jailbreak_detection ? "Yes" : "No",
      "Input Fairness (Unbiased) Score": bias_score["input"],
      "Output Fairness (Unbiased) Score": bias_score["output"],
      "Hallucination Score": hallucination_score,
      "Llama Guard Input": llama_guard_categories["input"],
      "Llama Guard Output": llama_guard_categories["output"],
    };

    // Check if both general_stats and detailed_stats are missing
    const noDetailedStats = !general_stats && !detailed_stats;

    return (
      <Box>
        {/* Tumeryk Metrics */}
        <Typography variant="h6" sx={{ marginTop: 1, marginBottom: 1 }}>
          Tumeryk Metrics
        </Typography>
        <Box sx={{ marginLeft: 2 }}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {Object.entries(transform_result).map(([key, value], index) => (
                  <TableRow key={index}>
                    <TableCell>{key}</TableCell>
                    <TableCell>{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* Trust Metrics Information */}
        {information && Object.keys(information).length > 0 && (
          <>
            <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 1 }}>
              Trust Metrics Information
            </Typography>

            <Box sx={{ marginLeft: 2 }}>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    {Object.entries(information).map(([key, value], index) => (
                      <TableRow key={index}>
                        <TableCell>{key}</TableCell>
                        <TableCell>{value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        )}

        <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 1 }}>
          Detailed & General Stats
        </Typography>

        {/* Detailed Stats */}
        {detailed_stats && (
          <Box sx={{ marginLeft: 2, marginTop: 2 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="detailed-stats-header"
                id="detailed-stats-header"
                style={{
                  borderRadius: "4px",
                }}
              >
                Detailed Stats
              </AccordionSummary>
              <AccordionDetails
                style={{
                  borderRadius: "4px",
                }}
              >
                {detailed_stats}
              </AccordionDetails>
            </Accordion>
          </Box>
        )}

        {/* General Stats */}
        {general_stats && (
          <Box sx={{ marginLeft: 2, marginTop: 2 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="detailed-stats-header"
                id="detailed-stats-header"
                style={{
                  borderRadius: "4px",
                }}
              >
                General Stats
              </AccordionSummary>
              <AccordionDetails
                style={{
                  borderRadius: "4px",
                }}
              >
                {general_stats}
              </AccordionDetails>
            </Accordion>
          </Box>
        )}

        {/* Bot Message Log Summary (as fallback) */}
        {noDetailedStats && (
          <>
            <Typography variant="h6" sx={{ marginLeft: 2, marginBottom: 2 }}>
              No Log Summary Available
            </Typography>
            <Typography
              variant="body2"
              sx={{ marginLeft: 2, marginBottom: 2, whiteSpace: "pre-wrap" }}
            >
              {bot_message_log_summary || "No log summary available"}
            </Typography>
          </>
        )}
      </Box>
    );
  };

  const handleCheckboxChange = (config) => {
    setSelectedConfig(
      selectedConfig && selectedConfig.id === config.id ? null : config
    );
    handleClearResponse();
    setGeneratedPrompts([]);
  };

  return (
    <div className="flex h-full w-full">
      <div
        style={{
          padding: "12px",
          marginTop: "180px",
          margin: "auto",
          backgroundColor: "white",
          borderRadius: 8,
        }}
        className="h-full w-full"
      >
        <div className="flex justify-between p-2 items-center mb-2">
          <div>
            <div
              className="text-sm"
              style={{ color: theme.tmryk_black_text_color }}
            >
              Manage policy settings
            </div>
          </div>
          <div className="flex">
            <Button
              onClick={() => setIsAddModalOpen(true)}
              style={{
                backgroundColor: theme.tmryk_background_color,
                color: "white",
                width: "195px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "20px",
              }}
            >
              Add Policy
            </Button>
          </div>
        </div>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="60vh"
          >
            <CircularProgress />
          </Box>
        ) : (
          <div
            style={{
              display: "flex",
              maxHeight: "calc(100% - 100px)",
              overflowY: "auto",
            }}
          >
            <div
              className="w-[47.35rem]"
              style={{ maxHeight: "750px", overflowY: "auto" }}
            >
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      height: "42px",
                      maxWidth: "47.35rem",
                      backgroundColor: theme.tmryk_background_color,
                    }}
                  >
                    {/* First Column: Empty (aligns with checkbox column) */}
                    <TableCell
                      style={{
                        color: "white",
                        padding: "6px 16px 6px 0", // Match padding with TableBody
                        borderBottom: "none",
                      }}
                    ></TableCell>

                    {/* Second Column: Policy ID */}
                    <TableCell
                      style={{
                        color: "white",
                        padding: "6px 32px 6px 0", // Match padding with TableBody
                        borderBottom: "none",
                      }}
                    >
                      Policy ID
                    </TableCell>

                    {/* Third Column: "+" Button */}
                    <TableCell
                      style={{
                        color: "white",
                        padding: "6px 6px 6px 0",
                        borderBottom: "none",
                      }}
                    >
                      <Tooltip title="Import Policy" arrow>
                        <IconButton onClick={() => setIsImportModalOpen(true)}>
                          <AddIcon sx={{ color: "white" }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {configs.map((config, index) => (
                    <TableRow
                      key={index}
                      style={{
                        height: "54px",
                        maxWidth: "600px",
                        backgroundColor:
                          selectedConfig && selectedConfig.id === config.id
                            ? theme.tmryk_background_color
                            : index % 2 === 0
                            ? "white"
                            : "white",
                        color:
                          selectedConfig && selectedConfig.id === config.id
                            ? "white"
                            : "white",
                      }}
                    >
                      <TableCell
                        style={{
                          color:
                            selectedConfig && selectedConfig.id === config.id
                              ? "white"
                              : theme.tmryk_black_text_color,
                          padding: "6px 16px 6px 0",
                          borderBottom: "none",
                        }}
                      >
                        <Checkbox
                          sx={{
                            color:
                              selectedConfig && selectedConfig.id === config.id
                                ? "white"
                                : theme.tmryk_background_color,
                            "&.Mui-checked": { color: "white" },
                          }}
                          checked={
                            selectedConfig
                              ? selectedConfig.id === config.id
                              : false
                          }
                          onChange={() => handleCheckboxChange(config)}
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            selectedConfig && selectedConfig.id === config.id
                              ? "white"
                              : theme.tmryk_black_text_color,
                          padding: "6px 32px 6px 0",
                          borderBottom: "none",
                        }}
                      >
                        {config.id}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            selectedConfig && selectedConfig.id === config.id
                              ? "white"
                              : theme.tmryk_black_text_color,
                          padding: "6px 6px 6px 0",
                          borderBottom: "none",
                        }}
                      >
                        <Button
                          onClick={(event) => handleMenuClick(event, config)}
                        >
                          <ActionDotIcon />
                        </Button>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                          getContentAnchorEl={null}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          sx={{
                            "& .MuiPaper-root": {
                              boxShadow: `0px 2px 8px ${theme.tmryk_box_shadow}`,
                              backgroundColor: "white",
                            },
                          }}
                        >
                          <MenuItem onClick={handleEditClick}>Edit</MenuItem>
                          <MenuItem onClick={handleExportClick}>
                            Export
                          </MenuItem>
                          <MenuItem
                            onClick={handleDeleteClick}
                            sx={{
                              color: "red",
                              "&:hover": {
                                backgroundColor: "#FDECEA",
                                color: "red",
                              },
                            }}
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            <div
              style={{
                width: "2px",
                backgroundColor: "#ccc",
                margin: "0 16px",
              }}
            ></div>
            <div
              className="w-full"
              style={{
                maxHeight: "100%",
                overflowY: "auto",
                overflowX: "auto",
              }}
            >
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                TabIndicatorProps={{
                  style: { backgroundColor: theme.tmryk_background_color },
                }}
              >
                <Tab
                  label="Edit Policy"
                  style={{
                    color:
                      selectedTab === 0
                        ? "white"
                        : theme.tmryk_background_color,
                    backgroundColor:
                      selectedTab === 0
                        ? theme.tmryk_background_color
                        : "inherit",
                    borderRadius: selectedTab === 0 ? "8px 8px 0 0" : "8px",
                  }}
                />
                <Tab
                  label="Test Policy"
                  style={{
                    color:
                      selectedTab === 1
                        ? "white"
                        : theme.tmryk_background_color,
                    backgroundColor:
                      selectedTab === 1
                        ? theme.tmryk_background_color
                        : "inherit",
                    borderRadius: selectedTab === 1 ? "8px 8px 0 0" : "8px",
                  }}
                />
              </Tabs>
              <div style={{ display: selectedTab === 0 ? "block" : "none" }}>
                {renderEditConfigTab()}
              </div>
              <div style={{ display: selectedTab === 1 ? "block" : "none" }}>
                {renderTestConfigTab()}
              </div>
            </div>
          </div>
        )}
      </div>

      {isAddModalOpen && (
        <AddConfigModal
          isModalOpen={isAddModalOpen}
          setIsModalOpen={setIsAddModalOpen}
          onConfigAdded={handleNewConfigAdded}
          selectedConfig={selectedConfig}
        />
      )}
      {isUpdateModalOpen && selectedConfig && (
        <UpdatePolicyModal
          isModalOpen={isUpdateModalOpen}
          setIsModalOpen={setIsUpdateModalOpen}
          onConfigUpdated={handleConfigUpdated}
          configs={configs}
          selectedConfig={selectedConfig}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteConfigModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          selectedConfig={selectedConfig}
          onSuccess={async () => {
            toast.success("Policy deleted successfully!");
            setSelectedConfig(null);
            await fetchConfigs();
          }}
        />
      )}
      {isDeleteFileModalOpen && (
        <DeleteConfigFileModal
          isModalOpen={isDeleteFileModalOpen}
          setIsModalOpen={setIsDeleteFileModalOpen}
          configName={selectedConfig?.id}
          fileName={selectedFileName}
          onSuccess={() => {
            setFilesContent((prev) => {
              const newContent = { ...prev };
              delete newContent[selectedFileName];
              return newContent;
            });
            setFileNames((prev) =>
              prev.filter((name) => name !== selectedFileName)
            );
            setSelectedFileName("");
            setSelectedFileContent("");
            toast.success("File deleted successfully!");
          }}
        />
      )}
      {isAddFileModalOpen && (
        <AddFileConfigModal
          isModalOpen={isAddFileModalOpen}
          setIsModalOpen={setIsAddFileModalOpen}
          configName={selectedConfig?.id}
          onSuccess={async () => {
            toast.success("Files added successfully!");
            await fetchFileContents(selectedConfig.id);
          }}
        />
      )}
      {isAddRecordModalOpen && (
        <AddRecordModal
          isModalOpen={isAddRecordModalOpen}
          setIsModalOpen={setIsAddRecordModalOpen}
          promptMessage={promptMessage}
          apiResponse={apiResponse}
          openViewModal={(listName) => {
            setSelectedList(listName);
            setPostModalAction({ type: "view" });
          }}
          openDeleteModal={(listName) => {
            setSelectedList(listName);
            setPostModalAction({ type: "delete" });
          }}
          openEditModal={(listName) => {
            setSelectedList(listName);
            setPostModalAction({ type: "edit" });
          }}
        />
      )}
      {isViewModalOpen && (
        <ViewListModal
          isOpen={isViewModalOpen}
          setIsOpen={setIsViewModalOpen}
          listName={selectedList}
          setIsModalOpen={setIsAddRecordModalOpen}
        />
      )}
      {isDeleteCustomListModalOpen && (
        <DeleteCustomListModal
          isOpen={isDeleteCustomListModalOpen}
          setIsOpen={setIsDeleteCustomListModalOpen}
          listName={selectedList}
          onDeleteSuccess={() => {
            setSelectedList(null);
            toast.success("Custom list deleted successfully!");
          }}
          setParentIsOpen={setIsAddRecordModalOpen}
        />
      )}
      {isEditCustomListModalOpen && (
        <EditCustomListModal
          isOpen={isEditCustomListModalOpen}
          setIsOpen={setIsEditCustomListModalOpen}
          listName={selectedList}
          setIsModalOpen={setIsAddRecordModalOpen}
          fetchUpdatedList={fetchUpdatedList}
        />
      )}
      {isExportModalOpen && (
        <ExportPolicyModal
          isModalOpen={isExportModalOpen}
          setIsModalOpen={setIsExportModalOpen}
          selectedConfig={selectedConfig}
        />
      )}

      {isImportModalOpen && (
        <ImportPolicyModal
          isModalOpen={isImportModalOpen}
          setIsModalOpen={setIsImportModalOpen}
          onImportSuccess={async () => {
            toast.success("Policy imported successfully!");
            await fetchConfigs(); // Refresh the list of policies
          }}
        />
      )}
    </div>
  );
}

export default ConfigsPage;
