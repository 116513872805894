import React, { useState } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  createTheme,
  ThemeProvider,
  Alert,
  CircularProgress,
  AlertTitle,
  IconButton,
} from "@mui/material";
import tmrykLogo from "../assets/images/tmryk-logo-login.png";
import client from "../client";
import { MarkEmailUnreadOutlined, Send } from "@mui/icons-material";
import { toast } from "react-toastify";

const defaultTheme = createTheme();

// Email regex for validation
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [showResetPasswordEmailMessage, setShowResetPasswordEmailMessage] =
    useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setIsSubmitDisabled(true);
    setIsResendDisabled(true);
    setErrorMessage("");
    setShowResetPasswordEmailMessage(false);

    // Extract and validate the email
    const emailInput = email.trim();

    // Email format validation
    if (!emailRegex.test(emailInput)) {
      setIsSubmitting(false);
      setIsSubmitDisabled(false);
      setErrorMessage("Please enter a valid email address.");
      return; // Prevent form submission
    }
    client
      .post(
        "/forgot_password",
        { email: email },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setIsSubmitting(false);
          setShowResetPasswordEmailMessage(true);
          toast.success("Email Sent Successfully");

          setTimeout(() => setIsResendDisabled(false), 10 * 1000);
          setTimeout(() => setIsSubmitDisabled(false), 15 * 1000);
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        setIsSubmitDisabled(false);
        console.log(error);
        if (typeof error.response?.data?.detail === "string") {
          setErrorMessage(error.response?.data?.detail);
        } else {
          setErrorMessage("Something went wrong!");
        }
      });
  };

  const handleResendEmail = async () => {
    setIsResendDisabled(true); // Disable Resend button
    setIsSubmitting(true);
    setIsSubmitDisabled(true);
    try {
      await client.post(
        "/forgot_password",
        { email: email },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      toast.success("Resend email successfully");
    } catch (error) {
      console.log(error);
      if (typeof error.response?.data?.detail === "string") {
        toast.fail(
          error.response?.data?.detail ||
            "Failed to resend email. Please try again."
        );
      } else {
        toast.fail("Something went wrong!");
      }
    } finally {
      setIsSubmitting(false);
      setIsSubmitDisabled(false);
    }
  };

  return (
    <div>
      <div>
        <img
          src={tmrykLogo}
          alt="tmryk logo"
          className="w-[32rem] h-48 mx-auto mt-10"
        />
      </div>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Forgot Your Password
            </Typography>
            {!showResetPasswordEmailMessage && (
              <Typography
                align="center"
                gutterBottom={true}
                color="gray"
                component="h2"
                variant="subtitle1"
                sx={{ mt: 2 }}
              >
                No worries! Enter your email address below.we'll send you a link
                to reset your password.
              </Typography>
            )}
            {errorMessage && (
              <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
                {errorMessage}
              </Alert>
            )}
            {showResetPasswordEmailMessage && (
              <Alert
                icon={<MarkEmailUnreadOutlined />}
                severity="success"
                sx={{ mt: 2, width: "100%" }}
                action={
                  <IconButton
                    disabled={isResendDisabled}
                    onClick={handleResendEmail}
                    sx={{
                      color: isResendDisabled ? "gray" : "inherit", // Conditional color change
                    }}
                  >
                    <Send />
                  </IconButton>
                }
              >
                <AlertTitle>Check Your Email</AlertTitle>
                We sent password reset link to your email. Please check your
                email.
                {!isResendDisabled && (
                  <>
                    <br /> Didn't received an email ? click on resend button.
                  </>
                )}
              </Alert>
            )}
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1, width: "70%" }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                type="email"
                value={email}
                placeholder="Enter your email address"
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
                autoFocus
                disabled={isSubmitDisabled || isSubmitting}
              />
              <Button
                disabled={isSubmitDisabled || isSubmitting || !email}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {!isSubmitting ? "Submit" : <CircularProgress size="1.5rem" />}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/login" variant="body2">
                    Back to Sign In ?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
}
