import React, { useEffect, useState } from "react";
import {
  Button,
  CssBaseline,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  createTheme,
  ThemeProvider,
  Alert,
  CircularProgress,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import tmrykLogo from "../assets/images/tmryk-logo-login.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import client from "../client";
import { toast } from "react-toastify";

const defaultTheme = createTheme();

export default function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordsMatchError, setPasswordsMatchError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Function to parse query parameters from the URL
  const queryParams = new URLSearchParams(location.search);

  const handlePasswordVisibility = () => setShowPassword((prev) => !prev);
  const handleConfirmPasswordVisibility = () =>
    setShowConfirmPassword((prev) => !prev);

  // Set default values from query params on page load
  useEffect(() => {
    setToken(queryParams.get("token") || "");
  }, [location.search]); // Re-run whenever the query string changes

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");
    setShowPassword(false);
    setShowConfirmPassword(false);
    setPasswordsMatchError("");

    // Validate Confirm Password
    if (!token) {
      setIsSubmitting(false);
      setErrorMessage("Token requires to reset the password!");
      return;
    }

    // Validate Confirm Password
    if (password !== confirmPassword) {
      setIsSubmitting(false);
      setPasswordsMatchError("Passwords do not match!");
      return;
    }

    client
      .post(
        "/reset_password",
        { token: token, password: password },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then(() => {
        setIsSubmitting(false);
        toast.success("Password reset successfully");
        navigate("/login", { replace: true });
      })
      .catch((error) => {
        setIsSubmitting(false);
        if (typeof error.response?.data?.detail === "string") {
          setErrorMessage(error.response?.data?.detail);
        } else {
          setErrorMessage("Something went wrong!");
        }
      });
  };

  return (
    <div>
      <div>
        <img
          src={tmrykLogo}
          alt="tmryk logo"
          className="w-[32rem] h-48 mx-auto mt-10"
        />
      </div>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Create a New Password
            </Typography>
            {!errorMessage && (
              <Typography
                align="center"
                gutterBottom={true}
                color="gray"
                component="h2"
                variant="subtitle1"
                sx={{ mt: 2 }}
              >
                Enter your new password below to complete the reset process.
              </Typography>
            )}
            {errorMessage && (
              <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
                {errorMessage}
              </Alert>
            )}
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <FormControl
                margin="normal"
                fullWidth
                required
                variant="outlined"
                disabled={isSubmitting}
              >
                <InputLabel>New Password</InputLabel>
                <OutlinedInput
                  required
                  fullWidth
                  label="New Password"
                  name="password"
                  id="password"
                  autoComplete="new-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={handlePasswordVisibility} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <FormControl
                margin="normal"
                fullWidth
                required
                error={!!passwordsMatchError}
                variant="outlined"
                disabled={isSubmitting}
              >
                <InputLabel>Repeat New Password</InputLabel>
                <OutlinedInput
                  required
                  fullWidth
                  label="Repeat New Password"
                  name="repeat_new_password"
                  id="repeat_new_password"
                  autoComplete="repeat-new-password"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleConfirmPasswordVisibility}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>{passwordsMatchError}</FormHelperText>
              </FormControl>
              <Button
                disabled={isSubmitting || !password || !confirmPassword}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {!isSubmitting ? "Submit" : <CircularProgress size="1.5rem" />}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/login" variant="body2">
                    Back to Sign In ?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
}
