import { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Alert, CircularProgress } from '@mui/material';
import client from '../../../client';
import theme from '../../../theme';

export default function EditUserModal({ isModalOpen, setIsModalOpen, selectedUser, currentUser, roles, onUserUpdated }) {
    const formRef = useRef(null);
    const cancelButtonRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedRole, setSelectedRole] = useState('');
    const [userData, setUserData] = useState({
        user_id: '',
        username: '',
        email: '',
        password: '',
        is_active: 'true',
        is_tenant_admin: 'false',
        is_superuser: 'false',
        role_id: '',
        tenant_id: ''
    });

    useEffect(() => {
        if (selectedUser) {
            setUserData({
                user_id: selectedUser.user_id,
                username: selectedUser.user_name,
                email: selectedUser.email,
                password: '',
                is_active: selectedUser.is_active ? 'true' : 'false',
                is_tenant_admin: selectedUser.is_tenant_admin ? 'true' : 'false',
                is_superuser: selectedUser.is_superuser ? 'true' : 'false',
                role_id: selectedUser.role_id,
                tenant_id: selectedUser.tenant_id
            });
            setSelectedRole(selectedUser.role_id);
        }
    }, [selectedUser]);

    const updateUser = async () => {
        setLoading(true);
        setError(null);

        const formData = new FormData(formRef.current);
        const username = formData.get("username");
        const email = formData.get("email");
        const password = formData.get("password");
        const role_id = formData.get("role_id");
        const tenant_id = formData.get("tenant_id");

        // Validation
        if (!username) {
            setError("Username is required.");
            setLoading(false);
            return;
        }
        if (!email) {
            setError("Email is required.");
            setLoading(false);
            return;
        }
        if (!role_id) {
            setError("Role selection is required.");
            setLoading(false);
            return;
        }
        if (currentUser.is_superuser && !tenant_id) {
            setError("Tenant ID is required.");
            setLoading(false);
            return;
        }

        let userData = {};

        if (currentUser.is_superuser) {
            userData = {
                id: selectedUser.user_id,
                tenant_id: parseInt(tenant_id, 10),
                username: username,
                email: email,
                password: password,
                is_active: formData.get("is_active") === "true",
                is_tenant_admin: formData.get("is_tenant_admin") === "true",
                is_superuser: formData.get("is_superuser") === "true",
                role_id: parseInt(role_id, 10),
            };
        } else if (currentUser.is_tenant_admin) {
            userData = {
                id: selectedUser.user_id,
                tenant_id: currentUser.tenantId,
                username: username,
                email: email,
                password: "0", // Retain existing password if not provided
                is_active: true,
                is_tenant_admin: formData.get("is_tenant_admin") === "true",
                is_superuser: false,
                role_id: parseInt(role_id, 10),
            };
        }

        const endpoint = `/update_user/${selectedUser.user_id}`;

        try {
            await client.post(endpoint, userData);
            onUserUpdated(); // Callback to refresh the user list or perform other actions
            setIsModalOpen(false);
        } catch (error) {
            const errorMessage = error.response?.data?.detail || "Error updating user. Please try again.";
            setError(JSON.stringify(errorMessage));
            console.error("Error updating user:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Transition.Root show={isModalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={() => setIsModalOpen(false)}>
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                    <div className="flex h-5/6 flex-col overflow-y-scroll bg-white shadow-xl rounded-lg"
                                        style={{ marginTop: '80px', marginRight: '15px' }}>
                                        <div className="px-4 py-6 sm:px-6 bg-white rounded-t-lg">
                                            <div className="flex items-start justify-between">
                                                <Dialog.Title className="text-lg font-medium" style={{ color: theme.tmryk_background_color }}>Edit User</Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="rounded-md bg-white text-gray-400 hover:text-white hover:bg-[#031A58] focus:outline-none focus:ring-2"
                                                        onClick={() => setIsModalOpen(false)}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative flex-1 px-4 py-6 sm:px-6">
                                            <form ref={formRef} className="space-y-6" onSubmit={(e) => e.preventDefault()}>
                                                {error && (
                                                    <Alert severity="error">
                                                        {typeof error === 'string' ? error : JSON.stringify(error)}
                                                    </Alert>
                                                )}
                                                <div className="space-y-4">
                                                    <TextField
                                                        id="user_id"
                                                        name="user_id"
                                                        label="User ID"
                                                        value={userData.user_id}
                                                        fullWidth
                                                        required
                                                        disabled
                                                        className="w-full bg-white border-2 border-green-700 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
                                                    />
                                                    <TextField
                                                        id="username"
                                                        name="username"
                                                        label="Username"
                                                        value={userData.username}
                                                        onChange={(e) => setUserData({ ...userData, username: e.target.value })}
                                                        fullWidth
                                                        required
                                                        className="w-full bg-white border-2 border-green-700 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
                                                    />
                                                    <TextField
                                                        id="email"
                                                        name="email"
                                                        label="Email"
                                                        type="email"
                                                        value={userData.email}
                                                        onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                                                        fullWidth
                                                        required
                                                        className="w-full bg-white border-2 border-green-700 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
                                                    />
                                                    {currentUser.is_superuser && (
                                                        <TextField
                                                            id="password"
                                                            name="password"
                                                            label="Reset Password"
                                                            type="password"
                                                            placeholder='Fill in only if you want to reset the password.'
                                                            value={userData.password}
                                                            onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                                                            fullWidth
                                                            className="w-full bg-white border-2 border-green-700 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
                                                        />
                                                    )}
                                                    {currentUser.is_superuser && (
                                                        <FormControl fullWidth>
                                                            <InputLabel className="text-gray-700">Active Status</InputLabel>
                                                            <Select
                                                                name="is_active"
                                                                label="Active Status"
                                                                value={userData.is_active}
                                                                onChange={(e) => setUserData({ ...userData, is_active: e.target.value })}
                                                                className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
                                                            >
                                                                <MenuItem value="true">Active</MenuItem>
                                                                <MenuItem value="false">Inactive</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    )}
                                                    <FormControl fullWidth>
                                                        <InputLabel className="text-gray-700">Tenant Admin</InputLabel>
                                                        <Select
                                                            name="is_tenant_admin"
                                                            label="Tenant Admin"
                                                            value={userData.is_tenant_admin}
                                                            onChange={(e) => setUserData({ ...userData, is_tenant_admin: e.target.value })}
                                                            className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
                                                        >
                                                            <MenuItem value="true">Tenant Admin</MenuItem>
                                                            <MenuItem value="false">Chat/Agent User</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    {currentUser.is_superuser && (
                                                        <FormControl fullWidth>
                                                            <InputLabel className="text-gray-700">Superuser</InputLabel>
                                                            <Select
                                                                name="is_superuser"
                                                                label="Superuser"
                                                                value={userData.is_superuser}
                                                                onChange={(e) => setUserData({ ...userData, is_superuser: e.target.value })}
                                                                className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
                                                            >
                                                                <MenuItem value="true">Superuser</MenuItem>
                                                                <MenuItem value="false">Not Superuser</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    )}
                                                    <FormControl fullWidth>
                                                        <InputLabel className="text-gray-700">Select Role</InputLabel>
                                                        <Select
                                                            name="role_id"
                                                            label="Select Role"
                                                            value={selectedRole}
                                                            onChange={(e) => setSelectedRole(e.target.value)}
                                                            className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
                                                        >
                                                            {roles.map((role) => (
                                                                <MenuItem key={role.role_id} value={role.role_id}>{role.role_name}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    {currentUser.is_superuser && (
                                                        <TextField
                                                            id="tenant_id"
                                                            name="tenant_id"
                                                            label="Tenant ID"
                                                            type="number"
                                                            value={userData.tenant_id}
                                                            onChange={(e) => setUserData({ ...userData, tenant_id: e.target.value })}
                                                            fullWidth
                                                            className="w-full bg-white border-2 border-green-700 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
                                                        />
                                                    )}
                                                </div>
                                                <div className="mt-4 flex justify-end justify-center ml-2 space-x-3">
                                                    <Button
                                                        variant="contained"
                                                        onClick={updateUser}
                                                        style={{
                                                            backgroundColor: theme.tmryk_background_color,
                                                            color: 'white',
                                                            width: '150px',
                                                            height: '48px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            textTransform: 'none',
                                                            fontWeight: 'bold',
                                                            boxShadow: '0 3px 5px 2px rgba(84, 130, 78, .3)',
                                                        }}
                                                        disabled={loading}
                                                    >
                                                        {loading ? <CircularProgress size={24} color="inherit" /> : 'Update User'}
                                                    </Button>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => setIsModalOpen(false)}
                                                        ref={cancelButtonRef}
                                                        style={{
                                                            borderColor: theme.tmryk_background_color,
                                                            color: theme.tmryk_background_color,
                                                            width: '150px',
                                                            height: '48px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            textTransform: 'none',
                                                            fontWeight: 'bold',
                                                        }}
                                                        disabled={loading}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
