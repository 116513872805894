import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  IconButton,
  Snackbar,
  Alert,
  Box,
  Typography,
  Grid,
  InputAdornment,
  CircularProgress,
  Paper,
  Popover,
  Tooltip,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import client from "../client";
import theme from "../theme";
import UserInfoUpdateModal from "../components/specific/UserInfoUpdateModal";
import { SketchPicker } from "react-color";

const UserSettingsPage = () => {
  const [user, setUser] = useState({ username: "", email: "", tenantName: "" });
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedUserInfo, setUpdatedUserInfo] = useState({});

  //Distribution Categories
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [resettingCategory, setCategoryResetting] = useState(false);
  
  // For Trust Metrics Settings
  const [trustMetrics, setTrustMetrics] = useState([]);
  const [loadingTrustMetrics, setLoadingTrustMetrics] = useState(true);
  const [resettingMetrics, setMetricsResetting] = useState(false);

  // Color Picker
  const [anchorEls, setAnchorEls] = useState({});

  // Total Weights
  const total_weights = parseFloat([...trustMetrics].reduce((acc, curr) => acc + curr.weight, 0)).toFixed(2)

  const fetchUserInfo = () => {
    client
      .get("/user_info")
      .then((response) => {
        setUser(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user info:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchUserInfo();
    fetchCategories();
    fetchTrustMetrics();
  }, []);

  const handleCurrentPasswordChange = (e) => {
    setCurrentPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmNewPasswordChange = (e) => {
    setConfirmNewPassword(e.target.value);
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();

    const reqBody = {
      current_password: currentPassword,
      new_password: newPassword,
      confirm_password: confirmNewPassword,
    };

    client
      .post("/update_password", reqBody)
      .then((response) => {
        setSnackbarMessage(response.data.msg);
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      })
      .catch((error) => {
        setSnackbarMessage(error.response.data.detail);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleShowConfirmNewPassword = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };

  const handleUserInfoChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleUserInfoSubmit = (e) => {
    e.preventDefault();
    setUpdatedUserInfo(user);
    setIsModalOpen(true);
  };

  const fetchCategories = () => {
    client
      .get("/category_settings")
      .then((response) => {
        setCategories(response.data.categories);
        setLoadingCategories(false);
      })
      .catch((error) => {
        console.error("Error fetching category settings:", error);
        setLoadingCategories(false);
      });
  };

  const fetchTrustMetrics = () => {
    client
      .get("/trust_metric_settings")
      .then((response) => {
        setTrustMetrics(response.data.metric_weights);
        setLoadingTrustMetrics(false);
      })
      .catch((error) => {
        console.error("Error fetching trust metrics settings:", error);
        setLoadingTrustMetrics(false);
      });
  };

  const handleCategoryChange = (index, field, value) => {
    setCategories((prevCategories) => {
      const newCategories = [...prevCategories];
      newCategories[index] = { ...newCategories[index], [field]: value };
      return newCategories;
    });
  };

  const handleTrustMetricChange = (index, value) => {
    setTrustMetrics((prevMetrics) => {
      const newMetrics = [...prevMetrics];
      newMetrics[index] = { ...newMetrics[index], weight: value };
      return newMetrics;
    });
  };

  const addCategory = () => {
    if (categories.length >= 5) return;
    setCategories((prevCategories) => {
      return [
        ...prevCategories,
        {
          name: "",
          min_score: 0,
          max_score: 0,
          color: "#000000",
        },
      ];
    });
  };

  const removeCategory = (index) => {
    if (categories.length <= 2) return;
    setCategories(categories.filter((_, i) => i !== index));
  };

  // Validation for the Category setting data
  const checkCategoryRanges = (ranges) => {
    // Sort ranges by min_score in ascending order
    ranges.sort((a, b) => a.min_score - b.min_score);

    let lastMax = 0;
    let namesSet = new Set();

    for (let i = 0; i < ranges.length; i++) {
      const range = ranges[i];

      // Check if name is non-empty and unique
      if (!range.name || range.name.trim() === "") {
        setSnackbarMessage("Category name should not be empty!");
        return false;
      }

      if (namesSet.has(range.name)) {
        setSnackbarMessage(
          `Category "${range.name}" is not unique. Please choose a unique name.`
        );
        return false;
      }
      namesSet.add(range.name);

      // Check the minimum value should not be greater or equal to maximum score
      if (range.min_score >= range.max_score) {
        setSnackbarMessage(
          `Category "${range.name}" has a min_score that is greater than or equal to the max_score`
        );
        return false;
      }

      // Check if the current range starts where the last one ended
      if (range.min_score !== lastMax) {
        setSnackbarMessage(
          `Gap detected between ${lastMax} and ${range.min_score}`
        );
        return false; // There is a gap
      }

      // Update the lastMax to the current range's max_score
      lastMax = range.max_score;

      // Ensure no range exceeds 1000
      if (range.max_score > 1000) {
        setSnackbarMessage(`Range exceeds 1000: ${range.name}`);
        return false;
      }
    }

    // Ensure the last range ends at or below 1000
    if (lastMax !== 1000) {
      setSnackbarMessage(
        `The last range does not end at 1000, ends at ${lastMax}`
      );
      return false;
    }

    return true; // All ranges are valid, non-empty, and unique
  };

  // Validation for the Category setting data
  const checkTrustMetricsValues = () => {
    // Ensure that total weights need to be 1.
    if (total_weights != 1) {
      setSnackbarMessage(`Total Weights should be 1. Found: ${total_weights}`);
      return false;
    }

    return true;
  };

  const handleCategorySettingsSubmit = (e) => {
    e.preventDefault();
    if (checkCategoryRanges([...categories])) {
      client
        .post("/category_settings", { categories })
        .then((response) => {
          setSnackbarMessage(response.data.message);
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          fetchCategories();
        })
        .catch((error) => {
          const errorDetail = error.response?.data?.detail;
          let errorMessage = undefined;
          if (Array.isArray(errorDetail)) {
            errorMessage = errorDetail[0]?.msg
              .replace("Value error, ", "")
              .trim();
          } else if (typeof errorDetail === "string") {
            errorMessage = errorDetail;
          }
          setSnackbarMessage(
            errorMessage || "Failed to update category settings."
          );
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        });
    } else {
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleTrustMetricsSettingsSubmit = (e) => {
    e.preventDefault();
    if (checkTrustMetricsValues()) {
      client
        .post("/trust_metric_settings", { metric_weights: trustMetrics })
        .then((response) => {
          setSnackbarMessage(response.data.message);
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          fetchTrustMetrics();
        })
        .catch((error) => {
          const errorDetail = error.response?.data?.detail;
          let errorMessage = undefined;
          if (Array.isArray(errorDetail)) {
            errorMessage = errorDetail[0]?.msg
              .replace("Value error, ", "")
              .trim();
          } else if (typeof errorDetail === "string") {
            errorMessage = errorDetail;
          }
          setSnackbarMessage(
            errorMessage || "Failed to update trust metrics settings."
          );
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        });
    } else {
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleResetCategories = () => {
    setCategoryResetting(true);
    client
      .delete("/category_settings")
      .then((response) => {
        setCategories(response.data.categories);
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setCategoryResetting(false);
      })
      .catch((error) => {
        setSnackbarMessage(
          error.response?.data?.detail || "Error resetting categories."
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setCategoryResetting(false);
      });
  };

  const handleResetTrustMetrics = () => {
    setMetricsResetting(true);
    client
      .delete("/trust_metric_settings")
      .then((response) => {
        setTrustMetrics(response.data.metric_weights);
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setMetricsResetting(false);
      })
      .catch((error) => {
        setSnackbarMessage(
          error.response?.data?.detail || "Error resetting trust metrics."
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setMetricsResetting(false);
      });
  };

  const handleClickColorSwatch = (event, index) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [index]: event.currentTarget,
    }));
  };

  const handleCloseColorPicker = (index) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [index]: null,
    }));
  };

  const renderUserInfo = () => (
    <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
      <Typography
        variant="h6"
        mb={2}
        gutterBottom
        style={{ color: theme.tmryk_background_color }}
      >
        User Information
      </Typography>
      <form onSubmit={handleUserInfoSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Username"
              variant="outlined"
              name="username"
              value={user.username}
              onChange={handleUserInfoChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Current Email"
              variant="outlined"
              name="email"
              value={user.email}
              onChange={handleUserInfoChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Company Name"
              variant="outlined"
              name="tenantName"
              value={user.tenantName}
              onChange={handleUserInfoChange}
              fullWidth
            />
          </Grid>
        </Grid>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            type="submit"
            variant="contained"
            style={{
              backgroundColor: theme.tmryk_background_color,
              color: "white",
            }}
          >
            Save
          </Button>
        </Box>
      </form>
    </Paper>
  );

  const renderPasswordSettings = () => (
    <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
      <Typography
        variant="h6"
        mb={2}
        gutterBottom
        style={{ color: theme.tmryk_background_color }}
      >
        Change Password
      </Typography>
      <form onSubmit={handlePasswordSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              type="password"
              label="Current Password"
              name="current-password"
              variant="outlined"
              fullWidth
              required
              value={currentPassword}
              onChange={handleCurrentPasswordChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type={showNewPassword ? "text" : "password"}
              label="New Password"
              name="new-password"
              variant="outlined"
              fullWidth
              required
              value={newPassword}
              onChange={handleNewPasswordChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle new password visibility"
                      onClick={toggleShowNewPassword}
                      edge="end"
                    >
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type={showConfirmNewPassword ? "text" : "password"}
              label="Confirm New Password"
              name="confirm-new-password"
              variant="outlined"
              fullWidth
              required
              value={confirmNewPassword}
              onChange={handleConfirmNewPasswordChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirm new password visibility"
                      onClick={toggleShowConfirmNewPassword}
                      edge="end"
                    >
                      {showConfirmNewPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            type="submit"
            variant="contained"
            style={{
              backgroundColor: theme.tmryk_background_color,
              color: "white",
            }}
          >
            Save Password
          </Button>
        </Box>
      </form>
    </Paper>
  );

  const renderCategorySettings = () => (
    <Paper elevation={3} sx={{ p: 2, marginBottom: 2 }}>
      {loadingCategories ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography
            variant="h6"
            mb={3}
            gutterBottom
            style={{ color: theme.tmryk_background_color }}
          >
            Category Settings
          </Typography>
          <form onSubmit={handleCategorySettingsSubmit}>
            {categories.map((category, index) => (
              <Grid
                container
                spacing={2}
                alignItems="center"
                key={index}
                sx={{ mb: 4 }}
              >
                <Grid item xs={user.is_tenant_admin ? 3 : 4}>
                  <TextField
                    label="Name"
                    value={category.name}
                    onChange={(e) =>
                      handleCategoryChange(index, "name", e.target.value)
                    }
                    fullWidth
                    size="small"
                    disabled={!user.is_tenant_admin}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Min Score"
                    type="number"
                    value={category.min_score}
                    onChange={(e) =>
                      handleCategoryChange(
                        index,
                        "min_score",
                        parseInt(e.target.value)
                      )
                    }
                    size="small"
                    fullWidth
                    disabled={!user.is_tenant_admin}
                    inputProps={{
                      min: "0", // Prevent going below 0
                      max: "1000", // Prevent going above 1000
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Max Score"
                    type="number"
                    value={category.max_score}
                    onChange={(e) =>
                      handleCategoryChange(
                        index,
                        "max_score",
                        parseInt(e.target.value)
                      )
                    }
                    size="small"
                    fullWidth
                    disabled={!user.is_tenant_admin}
                    inputProps={{
                      min: "0", // Prevent going below 0
                      max: "1000", // Prevent going above 1000
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={user.is_tenant_admin ? 1 : 2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      backgroundColor: category.color,
                      border: "1px solid #ccc",
                      borderRadius: 4,
                      cursor: "pointer",
                      pointerEvents: !user.is_tenant_admin ? "none" : "auto",
                    }}
                    onClick={(e) => handleClickColorSwatch(e, index)}
                    disabled={!user.is_tenant_admin}
                  />
                  <Popover
                    open={Boolean(anchorEls[index])}
                    anchorEl={anchorEls[index]}
                    onClose={() => handleCloseColorPicker(index)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <SketchPicker
                      color={category.color}
                      onChangeComplete={(color) =>
                        handleCategoryChange(index, "color", color.hex)
                      }
                    />
                  </Popover>
                </Grid>
                {user.is_tenant_admin && (
                  <Grid item xs={2}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => removeCategory(index)}
                      disabled={categories.length <= 2}
                      size="small"
                    >
                      Remove
                    </Button>
                  </Grid>
                )}
              </Grid>
            ))}
            {user.is_tenant_admin && (
              <>
                <Box mt={5} display="flex" justifyContent="space-between">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={addCategory}
                    disabled={categories.length >= 5}
                  >
                    Add Category
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleResetCategories}
                    disabled={resettingCategory}
                  >
                    Reset to Default Settings
                  </Button>
                </Box>
                <Box mt={2} display="flex" justifyContent="flex-end">
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: theme.tmryk_background_color,
                      color: "white",
                    }}
                  >
                    Save Categories
                  </Button>
                </Box>
              </>
            )}
          </form>
        </>
      )}
    </Paper>
  );

  const renderTrustMetricsSettings = () => (
    <Paper elevation={3} sx={{ p: 2, marginBottom: 2 }}>
      {loadingTrustMetrics ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
        <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      mb={2}
                    >
          <Typography
            variant="h6"
            gutterBottom
            style={{ color: theme.tmryk_background_color }}
          >
            Trust Metrics Weight Settings
          </Typography>
          <Tooltip title={"Total weight should be 1.00"} placement="top" arrow >
          <Alert variant="outlined" severity={total_weights == 1 ? "success" : "warning"} >
            Total Weight: {total_weights}
          </Alert>
          </Tooltip>
          </Box>
          <form onSubmit={handleTrustMetricsSettingsSubmit}>
            {trustMetrics.map((trust_metric, index) => (
              <Grid
                container
                spacing={2}
                alignItems="center"
                key={index}
                sx={{ mb: 3 }}
              >
                <Grid item xs={8}>
                  <TextField
                    value={trust_metric.name}
                    fullWidth
                    size="small"
                    onChange={undefined}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    type="number"
                    value={parseFloat(trust_metric.weight).toFixed(2)}
                    onChange={(e) =>
                      handleTrustMetricChange(index, parseFloat(parseFloat(e.target.value).toFixed(2)))
                    }
                    size="small"
                    fullWidth
                    disabled={!user.is_tenant_admin}
                    inputProps={{
                      step: "0.01", // Define step for spinner (increments/decrements by 0.01)
                      min: "0", // Prevent going below 0
                    }}
                  />
                </Grid>
              </Grid>
            ))}
            {user.is_tenant_admin && (
              <>
                <Box mt={2} display="flex" justifyContent="space-between">
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleResetTrustMetrics}
                    disabled={resettingMetrics}
                    size="small"
                  >
                    Reset to Default Settings
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: theme.tmryk_background_color,
                      color: "white",
                    }}
                  >
                    Save Trust Metrics Weights
                  </Button>
                </Box>
              </>
            )}
          </form>
        </>
      )}
    </Paper>
  );

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, mt: 2, mr: 2 }}>
      <Grid container spacing={1}>
        {/* Left Column */}
        <Grid item xs={12} md={6}>
          <Box sx={{ px: 2 }}>
            {renderUserInfo()}
            {renderCategorySettings()}
          </Box>
        </Grid>
        {/* Right Column */}
        <Grid item xs={12} md={6}>
          <Box sx={{ px: 2 }}>
            {renderPasswordSettings()}
            {renderTrustMetricsSettings()}
          </Box>
        </Grid>
      </Grid>
      {/* Snackbar and Modals */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <UserInfoUpdateModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        user={user}
        updatedUserInfo={updatedUserInfo}
        fetchUserInfo={fetchUserInfo}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarSeverity={setSnackbarSeverity}
        setSnackbarOpen={setSnackbarOpen}
      />
    </Box>
  );
};
export default UserSettingsPage;
